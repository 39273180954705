import React from 'react';
import { Link } from 'react-router-dom';

const CTASection = () => {
  return (
    <section className="bg-[#0A0F29] text-white py-32 px-6 flex justify-center">
      <div className="max-w-3xl text-center border border-gray-700 rounded-lg p-8">
        <h2 className="text-2xl md:text-3xl font-semibold italic text-blue-400 mb-4">
          Start Now and Transform your Recruitment
        </h2>
        <p className="text-gray-300 mb-6">
          Sign up in minutes, share the details of your open positions and any specific requirements, 
          and Rysconnect will take care of the rest.
        </p>
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-6 rounded-full transition-colors duration-300">
        <Link to="/signup">Connnect Now</Link>
        </button>
      </div>
    </section>
  );
};

export default CTASection;
