import React, { useState } from 'react';
import { FiClock, FiTrendingUp , FiCheckSquare, FiBarChart  } from 'react-icons/fi';

const icons = {
  "Save Time": <FiClock />,
  "Spend Smarter": <FiTrendingUp />,
  "Real Results with Advanced AI": <FiCheckSquare />,
  "Performance That Counts": <FiBarChart  />,
};

const BenefitsCard = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDescription = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div 
      className={`relative group w-72 overflow-hidden rounded-lg cursor-pointer shadow-lg transition-colors duration-300 ${
        isOpen ? 'bg-orange-500' : 'bg-blue-600'
      } hover:bg-orange-500`}
      onClick={toggleDescription}
    >
      <div className="relative z-10 flex flex-col justify-center items-center py-6 text-white text-center">
        <div className="text-4xl mb-2">{icons[title]}</div>
        <h3 className="text-lg font-semibold">{title}</h3>
        {isOpen && (
          <p className="mt-4 text-sm opacity-90 px-4">{description}</p>
        )}
      </div>
    </div>
  );
};

export default BenefitsCard;
