import React, { useEffect, useState } from 'react';
import imagen from '../../assets/LANDING/ILUSTRACIONES-RYSCONNECT-ENGRANES.png';
import logo from "../../assets/Logo/simbolo-SF-04.png";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FormGroup from '../../components/SignUp/FormGroup';
import PasswordInput from '../../components/SignUp/PasswordInput';
import PhoneNumberInput from '../../components/SignUp/PhoneNumberInput';
import SelectInput from '../../components/SignUp/SelectInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const INITIAL_FORM = {
    firstName: '',
    secondName: '',
    phoneNumber: '',
    email: '',
    password: '',
    userType: ''
};

const SignUp = () => {
    const user = useSelector((state) => state?.user);
    const navigate = useNavigate();
    const [form, setForm] = useState(INITIAL_FORM);
    const [showPassword, setShowPassword] = useState(false); 
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (user && user.isAuthenticated) {
            navigate('/');
        }
    }, [user, navigate]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleForm = async (event) => {
        event.preventDefault();
        const err = validation(form);
        if (err === null) {
            try {
                setErrors({});
                const formData = {
                    ...form,
                    phoneNumber: `+${form.phoneNumber}`  
                };

                const response = await axios.post(`/registeruser`, formData);
                if (response.status === 201) {
                    toast.success(`Thank you for registering ${response.data.firstName}, your user has been created successfully!`, {
                        position: "top-center",
                        autoClose: 3000,
                        onClose: () => {
                            setForm(INITIAL_FORM);
                            navigate('/signin');
                        }
                    });
                } 
                
            } catch (error) {
                if (error.response) {
                    toast.error(error.response.data.error, {
                        position: "top-center"
                    });
                } else {
                    toast.error("An error occurred in the request. Please try again.", {
                        position: "top-center"
                    });
                }
            }
        } else {
            setErrors(err);
            toast.error("First fix the mistakes", {
                position: "top-center"
            });
        }
    };

    const validation = (data) => {
        let isError = false
        let incorrect = {}
        let firstName = data.firstName.trim()
        let secondName = data.secondName.trim()
        let phoneNumber = data.phoneNumber.trim()
        let email = data.email.trim()
        let password = data.password.trim()
        let userType = data.userType.trim()
    
        const regexNumber = RegExp(/^[0-9]+$/)
        const regexLetters = RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/)
        const regexEmail = RegExp(/\S+@\S+\.\S+/);
        const regexPass = RegExp(/.*\d.*/);
        const regexLetterForPassword = /[a-zA-Z]/; 
    
        if (!firstName){
            incorrect.firstName = "The first name cannot be empty."
            isError = true
        } else if(!regexLetters.test(firstName)){
            incorrect.firstName = "Only letters"
            isError = true
        }

        if (!secondName){
            incorrect.secondName = "The last name cannot be empty."
            isError = true
        } else if(!regexLetters.test(secondName)){
            incorrect.secondName = "Only letters"
            isError = true
        }

        if (!phoneNumber) {
            incorrect.phoneNumber = "The phone number cannot be empty."
            isError = true
        } else if (!regexNumber.test(phoneNumber)) {
            incorrect.phoneNumber = "Only numbers"
            isError = true
        } else if ( phoneNumber.toString().length < 10){
            incorrect.phoneNumber = 'It must be 10 digits'
            isError = true
        }

        if(!email){
            incorrect.email = "The email cannot be empty."
            isError = true
        } else if(!regexEmail.test(email)) {
            incorrect.email = "Only email format."
            isError = true
        }

        if (!regexPass.test(password)) {
            incorrect.password = "The password must have at least one number"
            isError = true
        } else if(data.password.length < 6){
            incorrect.password = "The password must be longer than 6"
            isError = true
        }else if (!regexLetterForPassword.test(password)) {
            incorrect.password = "The password must have at least one letter.";
            isError = true;
        } 

        if (!userType){
            incorrect.userType = "The user type cannot be empty."      
            isError = true
        }    
        return isError ? incorrect : null
    };

    return (
<div className="flex items-center justify-center min-h-screen bg-[#0A0F29] relative">
    <div className="absolute inset-0">
        <img 
            src={imagen} 
            alt="Background with gears and shapes" 
            className="w-full h-full object-cover opacity-30" 
        />
    </div>
    {/* Contenedor principal de la tarjeta */}
    <div className="relative z-10 p-1 rounded-lg max-w-lg w-full">
        {/* Gradiente con Blur */}
        <div className="absolute inset-0 bg-gradient-to-t from-[#000] via-[#2563EB] to-[#FF7616] blur-2xl opacity-45 rounded-lg"></div>
        {/* Contenido del formulario */}
        <div className="relative p-6 bg-[#0A0F29] shadow-xl rounded-lg">
            <div className="flex justify-center mb-4">
                <img src={logo} alt="Logo" className="w-14 h-20" />
            </div>
            <h2 className="text-3xl font-bold text-center text-white mb-6">Get Started with RysConnect Today</h2>
            <form className="space-y-5" onSubmit={handleForm}>
                <FormGroup
                    id="firstName"
                    label="First Name"
                    type="text"
                    value={form.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    error={errors.firstName}
                />
                <FormGroup
                    id="secondName"
                    label="Last Name"
                    type="text"
                    value={form.secondName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    error={errors.secondName}
                />
                <PhoneNumberInput
                    value={form.phoneNumber}
                    onChange={(phoneNumber) => setForm({ ...form, phoneNumber })}
                    error={errors.phoneNumber}
                />
                <FormGroup
                    id="email"
                    label="Email"
                    type="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Email"
                    error={errors.email}
                />
                <PasswordInput
                    id="password"
                    value={form.password}
                    onChange={handleChange}
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                    error={errors.password}
                />
                <SelectInput
                    id="userType"
                    value={form.userType}
                    onChange={handleChange}
                    error={errors.userType}
                    options={[
                        { value: "", label: "I’m a..." },
                        { value: "Candidate", label: "Candidate" },
                        { value: "Company", label: "Company" },
                    ]}
                />
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-orange-600 text-white rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 transition ease-in-out duration-300">
                    Sign Up
                </button>
            </form>
        </div>
    </div>
    <ToastContainer />
</div>

    );
};

export default SignUp;
