import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getJobById } from '../../redux/actions/Candidate/getJobById';
import JobHeader from '../../components/JobDetails/JobHeader';
import JobDetailsGrid from '../../components/JobDetails/JobDetailsGrid';
import JobDescriptionSection from '../../components/JobDetails/JobDescriptionSection';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';


const VacancyView = () => {
    const { id } = useParams();
    const job = useSelector(state => state.job)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(getJobById(id))
    }, [id, dispatch]);  


    if (!job) {
        return <p>Loading job details...</p>;
    }

return (
<div className="bg-[#0A0F29] pt-8">
    <div className="max-w-7xl mx-auto mb-5">
    <div className="border rounded-lg p-6">
        <JobHeader job={job} />
        <JobDetailsGrid job={job} />
        <JobDescriptionSection job={job} />
    </div>
    </div>
    <div className="py-12 bg-[#0A0F29] w-full">
    <div className="max-w-6xl mx-auto px-4 text-center">
        <Link to={`/vacancy-edit/${job.id}`}>
            <button className="mt-8 px-8 py-3 bg-blue-600 text-white font-bold rounded-full shadow-lg hover:bg-yellow-600 w-3/6">
                Edit
            </button>
        </Link>
    </div>
    </div>
    <ToastContainer />
</div>
);
};

export default VacancyView