import { useEffect, useState, useRef } from "react"
import AdminNewsTable from "../../components/News/CreateNewsComponents/AdminNewsTable"
import CreateNewsForm from "../../components/News/CreateNewsComponents/CreateNewsForm"
import axios from "axios"
import validations from "../../components/News/CreateNewsComponents/validations"
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux"
import { getNewsForAdmin } from "../../redux/actions/News/getNewsForAdmin"
import DOMPurify from "dompurify"


const INITIAL_FORM = {
    title: '',
    images: { files: [], value: '' }, //Must be {files: files, value: value of input}
    referenceLink:'',
}


const CreateNews = () =>{
    const formRef = useRef()
    const [form, setForm] = useState(INITIAL_FORM)
    const [body, setBody] = useState('')
    const newsFA = useSelector(state => state.newsFA)
    const [errors, setErrors] = useState({})
    const [formButton, setFormButton] = useState('Create')
    const dispatch = useDispatch()

    const handleQuillChange = (data) => {
        //sanitizar
        const sanitizedData = DOMPurify.sanitize(data)
        setBody(sanitizedData)
    }


    const handleChange = (event) => {
        const { name, value } = event.target
        setForm({...form, [name]: value})
    }
    
    const handleSubmitForm = async (event)=>{
        event.preventDefault()
        const dataToValidate = {...form, body:body}
        const err = validations(dataToValidate)
        //Verifica si hay errores, si no hay simplemente manda 
        if(err === null){
            try {
                if (formButton === 'Create') {
                    setErrors({})
                    //para ser posible el envio de imagenes se necesita un formData multipar-form-data
                    const formData = new FormData();
                    //Es necesario convertirlo a array para iterar porque es de tipo FileList originalmente y este no es iterable
                    const arrayOfImages = Array.isArray(form.images.files) ? [] : Array.from(form.images.files)
                    
                    formData.append('title', form.title);
                    formData.append('body', body);
                    formData.append('referenceLink', form.referenceLink);
                    //Iterar y añadir al formData ya que no se puede añadir nada que sea un array porque este tratara de hacerlo string
                    arrayOfImages.forEach((image) => {
                        formData.append(`images`, image);
                    });
    
                    const response = await axios.post('/add-news', formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    });
    
                    if (response.status === 201) {
                        
                        toast.success('Create success', {
                            autoClose: 2000,
                            onOpen:()=>{ dispatch(getNewsForAdmin()) }
                        })
                    }
                    setBody('')
                    setForm(INITIAL_FORM)
                }else{
                    setErrors({})
                    //para ser posible el envio de imagenes se necesita un formData multipar-form-data
                    const formData = new FormData();
                    //Es necesario convertirlo a array para iterar porque es de tipo FileList originalmente y este no es iterable
                    const arrayOfImages = Array.isArray(form.images.files) ? [] : Array.from(form.images.files)
                    
                    formData.append('title', form.title);
                    formData.append('body', body);
                    formData.append('referenceLink', form.referenceLink);
                    //Iterar y añadir al formData ya que no se puede añadir nada que sea un array porque este tratara de hacerlo string
                    arrayOfImages.forEach((image) => {
                        formData.append(`images`, image);
                    });
                    //el form.newId se asigna cuando se hace click al boton
                    const response = await axios.put(`/update-news/${form.newsId}`, formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    });
    
                    if (response.status === 200) {
                        toast.success('Update success',{
                            autoClose: 2000,
                            onOpen:()=>{ 
                                dispatch(getNewsForAdmin()) 
                            }
                        })
                    }
                    setForm(INITIAL_FORM)
                    setBody('')
                    setFormButton('Create')
                }
            } catch (error) {
                if (error.response) {
                    toast.error(error.response.data.error);
                } else {                    
                    toast.error("An error occurred in the request. Please try again.");
                }
            }
            
        }else{
            setErrors(err)
            toast.error("First fix the mistakes")
        }
    }

    const handleFileChange = (event) => {
        //Se puede intentar con un iterador y compare todas las imagenes para que tengan el formato correcto
        const files = event.target.files;
        const allowedExtensions = ['image/jpeg', 'image/png'];

        if (files.length <= 0) {
            setForm({
                ...form, images:{file: [], value: ''}
            })
            return toast.error("Upload something...")
        }

        if (files.length > 5) {
            setForm({
                ...form, images:{file: [], value: ''}
            })
            return toast.error("No more that 5 images...")
        }
        
        for (let i = 0; i < files.length; i++) {
            let file = files[i]

            if (!allowedExtensions.includes(file.type)) {
                setForm({
                    ...form, images:{file: [],value: ''}
                })
                return toast.error('Please upload a valid image file (JPEG, PNG)');
            }
        }

        setForm({
            ...form,
            images: {files:files, value:event.target.value}
        });
    }

    const handleDeleteButton = async (newId) => {
        if (confirm('Are you want delete this news?')) {
            try {
                const response = await axios.delete(`delete-news/${newId}`)
            
                if (response.status === 204) {
                    return toast.success("Delete success.",{
                        autoClose: 2000,
                        onOpen:()=>{ 
                            dispatch(getNewsForAdmin()) 
                        }
                    })
                }else{
                    return toast.error("An error occurred in the request. Please try again.");
                }
            } catch (error) {
                if (error.response) {
                    return toast.error(error.response.data.error);
                } else {                    
                    return toast.error("An error occurred in the request. Please try again.");
                }
            }
            
        }else{
            return
        }
    }

    const handleUpdateButton = ({newsId, title, body, referenceLink}) => {
        if (formRef.current) {
            const yOffset = -200 // Lo ajusta en pixeñes
            const yPosition = formRef.current.getBoundingClientRect().top + window.scrollY + yOffset; // Da el valor en pixeles de lo que esta alejado de la parte superior, tambien obtiene lo que te has desplazado con el scroll en pixeles y hace el calculo para referenciarte correctamente al formulario
        
            window.scrollTo({ top: yPosition, behavior: "smooth" });
        }
        
        setFormButton('Update')
        setForm({
            newsId: newsId,
            title: title,
            images: { files: [], value: '' },
            referenceLink: referenceLink
        })
        setBody(body)
    }

    useEffect(()=>{
        dispatch(getNewsForAdmin())
    }, [])

    return(
        <>
            <div className="mb-auto w-full flex items-center flex-col">
                <h1 className="mb-10 mt-10 text-3xl font-bold text-white">Create News</h1>
                <div className="bg-white w-2/4 mb-10 rounded-lg shadow-md border border-gray-200">
                    <CreateNewsForm formRef={formRef} form={form} handleChange={handleChange} handleSubmitForm={handleSubmitForm} errors={errors} handleFileChange={handleFileChange} formButton={formButton} handleQuillChange={handleQuillChange} body={body}/>
                </div>
                <h1 className="mb-10 mt-10 text-3xl font-bold text-white">Table</h1>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-3/4 mb-10">
                    <AdminNewsTable newsFA={newsFA} handleDeleteButton={handleDeleteButton} handleUpdateButton={handleUpdateButton}/>
                </div>

            </div>
            <ToastContainer />
        </>
    )
}

export default CreateNews
