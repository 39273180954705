import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({searchCandidates = null, searchJobs = null}) => {
    const [search, setSearch] = useState('')


    const onHandleChange = (event) =>{
        setSearch(event.target.value)
    }

    const onHandleSearch = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (searchCandidates) {
            searchCandidates(search);
        } else if (searchJobs) {
            searchJobs(search);
        } else {
            console.error('No search function provided');
        }
    }

    const onHandleKeyUp = (event) => {
        if (event.key === 'Enter') {
            onHandleSearch()
          }
    }

    return (
<div className="max-w-4xl mx-auto">
  <div className="flex justify-center mb-6">
    <div className="relative w-full max-w-3xl">
      {/* Input para búsqueda */}
      <input 
        type="text" 
        placeholder="Search" 
        className="w-full p-4 pl-6 pr-14 rounded-full bg-transparent border border-white text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onChange={onHandleChange}
        onKeyUp={onHandleKeyUp}
        value={search}
      />
      {/* Icono de búsqueda */}
      <FaSearch 
        onClick={onHandleSearch} 
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white cursor-pointer hover:text-gray-300"
      />
    </div>
  </div>
</div>

    );
}

export default SearchBar;
