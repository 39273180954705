


const AdminNewsTable = ({ newsFA, handleDeleteButton, handleUpdateButton }) => {
    return (
                    <table className="w-full text-sm text-left text-gray-600 border border-gray-200">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                            <tr>
                            <th scope="col" className="px-6 py-3 border-b border-gray-200">Title</th>
                            <th scope="col" className="px-6 py-3 border-b border-gray-200">Body</th>
                            <th scope="col" className="px-6 py-3 border-b border-gray-200">Images</th>
                            <th scope="col" className="px-6 py-3 border-b border-gray-200">Reference link</th>
                            <th scope="col" colSpan={2} className="text-center px-6 py-3 border-b border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {newsFA.map((oneNew)=>{
                                return(
                                    <tr key={oneNew.id} className="bg-white hover:bg-gray-50">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900">{oneNew.title}</th>
                                        <td className="px-6 py-4">{oneNew.body}</td>
                                        <td className="px-6 py-4 max-w-sm">{oneNew.images}</td>
                                        <td className="px-6 py-4">{oneNew.referenceLink}</td>
                                        <td className="py-2">
                                            <button 
                                            className="p-3 bg-red-500 text-white rounded-lg hover:bg-red-400"
                                            onClick={()=>{handleDeleteButton(oneNew.id)}}
                                            >Delete</button>
                                        </td>
                                        <td className="py-2">
                                            <button 
                                            className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-400"
                                            onClick={()=>{handleUpdateButton({newsId: oneNew.id,title: oneNew.title, body: oneNew.body, referenceLink: oneNew.referenceLink})}}
                                            >Edit</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
    )
}

export default AdminNewsTable