import React, { useState } from 'react';



const INITIAL_STATE_FILTERS = { 
  seniority:'',
  position:'',
  location:'',
  mainTechnology:[]
}


const Filters = ({filterCandidates, resetCandidatesFilter}) => {

const [selectState, setSelectState] = useState('')

const [filters, setFilters] = useState(INITIAL_STATE_FILTERS)

  const onHandleChangeFilters = (event) => {
    if (event.target.name === 'mainTechnology') {
      if(!filters[event.target.name].includes(event.target.value)){
        setSelectState(event.target.value)
        setFilters({...filters, [event.target.name]:[...filters[event.target.name], event.target.value]})
      }
    }else{
      setFilters({...filters, [event.target.name]:event.target.value})
    }
  }

  const onHandleClickTags = (tech) => {
    if (filters.mainTechnology.includes(tech)) {
      const result = filters.mainTechnology.filter(technology => technology != tech) 
      setFilters({...filters, mainTechnology: result})
    }
  }

  const onHandleFilterCandidates = () => {
    if (hasFilters) {
      filterCandidates(filters)
    }
  }

  const onHandleResetFilters = () =>{
    setFilters(INITIAL_STATE_FILTERS)
    resetCandidatesFilter()
  }

  const hasFilters = 
  filters.seniority || 
  filters.position || 
  filters.location || 
  filters.mainTechnology.length > 0;


    return (
        <div className="flex flex-col mb-8 md:mb-0 md:mr-12 space-y-4 w-full md:w-1/4 text-white">
            {/* Esta para cabios, para manejar otro tipo de logica */}
            <label htmlFor="">Seniority</label>
            <select key="Seniority" className="w-full p-2 border rounded-md text-gray-500" name='seniority' id='seniority' onChange={onHandleChangeFilters} value={filters.seniority}>
              <option value='' disabled>Select one...</option>
              <option value="Trainee">Trainee</option>
              <option value="Junior">Junior</option>
              <option value="Mid">Mid</option>
              <option value="Senior">Senior</option>
              <option value="Tech Lead">Tech Lead</option>
              <option value="Architect">Architect</option>
            </select>

            <label htmlFor="">Position</label>
              <select key="Position" name='position' className="w-full p-2 border rounded-md text-gray-500" onChange={onHandleChangeFilters} value={filters.position}>
                <option value='' disabled>Select one...</option>
                <option value="Android">Android</option>
                <option value="Back-end">Back-end</option>
                <option value="Blockchain">Blockchain</option>
                <option value="Cloud Engineer">Cloud Engineer</option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Data Engineer">Data Engineer</option>
                <option value="Data Scientist">Data Scientist</option>
                <option value="DevOps Engineer">DevOps Engineer</option>
                <option value="Embedded Systems">Embedded Systems</option>
                <option value="Front-end">Front-end</option>
                <option value="Full-Stack">Full-Stack</option>
                <option value="iOS">iOS</option>
                <option value="Machine Learning Engineer">Machine Learning Engineer</option>
                <option value="Mobile App Developer">Mobile App </option>
                <option value="Product Manager">Product Manager</option>
                <option value="QA Engineer">QA Engineer</option>
                <option value="Security Engineer">Security Engineer</option>
                <option value="Software Architect">Software Architect</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Software Engineer">Software Engineer</option>
                <option value="Solutions Architect">Solutions Architect</option>
                <option value="System Administrator">System Administrator</option>
                <option value="UI/UX Designer">UI/UX Designer</option>
                <option value="Web Developer">Web Developer</option>
              </select>

            <label htmlFor="">Country</label>
              <select key="Location" name='location' className="w-full p-2 border rounded-md text-gray-500" onChange={onHandleChangeFilters} value={filters.location}>
                <option value='' disabled>Select one...</option>
                <option value='LATAM'>LATAM</option>
                <option value='USA'>USA</option>
                <option value='Argentina'>Argentina</option>
{/*                 <option value='Belize'>Belize</option> */}
                <option value='Bolivia'>Bolivia</option>
                <option value='Brazil'>Brazil</option>
                <option value='Chile'>Chile</option>
                <option value='Colombia'>Colombia</option>
                <option value='Costa Rica'>Costa Rica</option>
                <option value='Cuba'>Cuba</option>
                <option value='Dominican Republic'>Dominican Republic</option>
                <option value='Ecuador'>Ecuador</option>
                <option value='El Salvador'>El Salvador</option>
                <option value='Guatemala'>Guatemala</option>
                <option value='Haiti'>Haiti</option>
                <option value='Honduras'>Honduras</option>
{/*                 <option value='Jamaica'>Jamaica</option> */}
                <option value='Mexico'>Mexico</option>
                <option value='Nicaragua'>Nicaragua</option>
                <option value='Panama'>Panama</option>
                <option value='Paraguay'>Paraguay</option>
                <option value='Peru'>Peru</option>
                <option value='Puerto Rico'>Puerto Rico</option>
{/*                 <option value='Suriname'>Suriname</option> */}
                <option value='Uruguay'>Uruguay</option>
                <option value='Venezuela'>Venezuela</option>
              </select>


              <label htmlFor="mainTechnology">Main Technology</label>
              <div className='flex flex-column justify-center items-center flex-wrap'>
                {filters?.mainTechnology.length > 0 && filters.mainTechnology.map((tech) => {
                  return (
                    <div key={tech} className='bg-blue-600 text-white rounded-xl p-1 m-1 cursor-pointer' onClick={() => onHandleClickTags(tech)}>
                      <span>{tech}</span>
                    </div>
                  )
                })}
              </div>
              <select key="Main Technology" name='mainTechnology' className="w-full p-2 border rounded-md text-gray-500" onChange={onHandleChangeFilters} value={selectState}>
              <option value='' disabled>Select one...</option>
              <option value='Angular'>Angular</option>
              <option value='AWS'>AWS</option>
              <option value='Azure'>Azure</option>
              <option value='C'>C</option>
              <option value='C++'>C++</option>
              <option value='C#'>C#</option>
              <option value='Django'>Django</option>
              <option value='Docker'>Docker</option>
              <option value='Elixir'>Elixir</option>
              <option value='Express'>Express</option>
              <option value='Flutter'>Flutter</option>
              <option value='Go'>Go</option>
              <option value='GraphQL'>GraphQL</option>
              <option value='Java'>Java</option>
              <option value='JavaScript'>JavaScript</option>
              <option value='Kotlin'>Kotlin</option>
              <option value='Kubernetes'>Kubernetes</option>
              <option value='Laravel'>Laravel</option>
              <option value='MongoDB'>MongoDB</option>
              <option value='MySQL'>MySQL</option>
              <option value='Node.js'>Node.js</option>
              <option value='Objective-C'>Objective-C</option>
              <option value='PHP'>PHP</option>
              <option value='PostgreSQL'>PostgreSQL</option>
              <option value='Python'>Python</option>
              <option value='Rails'>Rails</option>
              <option value='React'>React</option>
              <option value='Ruby'>Ruby</option>
              <option value='Rust'>Rust</option>
              <option value='Scala'>Scala</option>
              <option value='Spring'>Spring</option>
              <option value='Swift'>Swift</option>
              <option value='TypeScript'>TypeScript</option>
              <option value='Vue.js'>Vue.js</option>
              <option value='Perl'>Perl</option>
              <option value='Haskell'>Haskell</option>
              <option value='Dart'>Dart</option>
              <option value='SQL'>SQL</option>
              <option value='SQL Server'>SQL Server</option>
              <option value='R'>R</option>
              <option value='MATLAB'>MATLAB</option>
              <option value='Lua'>Lua</option>
              <option value='Groovy'>Groovy</option>
              <option value='Clojure'>Clojure</option>
              <option value='F#'>F#</option>
              <option value='Shell'>Shell</option>
              <option value='PowerShell'>PowerShell</option>
              <option value='HTML'>HTML</option>
              <option value='CSS'>CSS</option>
              <option value='Sass'>Sass</option>
              <option value='Less'>Less</option>
              <option value='NoSQL'>NoSQL</option>
              <option value='XML'>XML</option>
              <option value='JSON'>JSON</option>
              <option value='Markdown'>Markdown</option>
              <option value='Bootstrap'>Bootstrap</option>
              <option value='TailwindCSS'>TailwindCSS</option>
              <option value='Bulma'>Bulma</option>
              <option value='Foundation'>Foundation</option>
              <option value='Materialize'>Materialize</option>
              <option value='Semantic UI'>Semantic UI</option>
              <option value='jQuery'>jQuery</option>
              <option value='Backbone.js'>Backbone.js</option>
              <option value='AngularJS'>AngularJS</option>
              <option value='Svelte'>Svelte</option>
              <option value='Ember.js'>Ember.js</option>
              <option value='Redux'>Redux</option>
              <option value='MobX'>MobX</option>
              <option value='Next.js'>Next.js</option>
              <option value='Nuxt.js'>Nuxt.js</option>
              <option value='Gatsby'>Gatsby</option>
              <option value='Grunt'>Grunt</option>
              <option value='Gulp'>Gulp</option>
              <option value='Webpack'>Webpack</option>
              <option value='Parcel'>Parcel</option>
              <option value='Rollup'>Rollup</option>
              <option value='Babel'>Babel</option>
              <option value='Eslint'>Eslint</option>
              <option value='Prettier'>Prettier</option>
              <option value='Jest'>Jest</option>
              <option value='Mocha'>Mocha</option>
              <option value='Chai'>Chai</option>
              <option value='Enzyme'>Enzyme</option>
              <option value='Cypress'>Cypress</option>
              <option value='Protractor'>Protractor</option>
              <option value='Selenium'>Selenium</option>
              <option value='Puppeteer'>Puppeteer</option>
              <option value='Storybook'>Storybook</option>
              <option value='Apollo'>Apollo</option>
              <option value='Relay'>Relay</option>
              <option value='REST'>REST</option>
              <option value='Axios'>Axios</option>
              <option value='Fetch'>Fetch</option>
              <option value='GCP'>GCP</option>
              <option value='Heroku'>Heroku</option>
              <option value='Netlify'>Netlify</option>
              <option value='Vercel'>Vercel</option>
              <option value='CI/CD'>CI/CD</option>
              <option value='Jenkins'>Jenkins</option>
              <option value='Travis CI'>Travis CI</option>
              <option value='CircleCI'>CircleCI</option>
              <option value='Prometheus'>Prometheus</option>
              <option value='Kubernetes'>Kubernetes</option>
              <option value='Rust'>Rust</option>
              <option value='Grafana'>Grafana</option>
              <option value='Prometheus'>Prometheus</option>
              <option value='Elasticsearch'>Elasticsearch</option>
              <option value='Logstash'>Logstash</option>
              <option value='Kibana'>Kibana</option>
              <option value='Splunk'>Splunk</option>
              <option value='New Relic'>New Relic</option>
              <option value='Datadog'>Datadog</option>
              <option value='Splunk'>Splunk</option>
              <option value='Tableu'>Tableu</option>
              <option value='Terraform'>Terraform</option>
              <option value='GitHub Actions'>GitHub Actions</option>
              <option value='Bitbucket Pipelines'>Bitbucket Pipelines</option>
            </select>


            
          <button className='mt-6 px-4 py-2 bg-orange-600 text-white rounded-md' onClick={onHandleFilterCandidates}
            disabled={!hasFilters}
          >Apply filters</button>
          <button className='mt-6 px-4 py-2 bg-orange-600 text-white rounded-md' onClick={onHandleResetFilters}>Reset filters</button>
        </div>
    );
}

export default Filters;
