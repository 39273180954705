import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importamos useNavigate
const { VITE_BASE_URL } = import.meta.env;
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineEye } from 'react-icons/ai';
import { FaRegEdit, FaUserPlus } from 'react-icons/fa';
import { BiLinkAlt } from 'react-icons/bi';
import { AiFillLock } from 'react-icons/ai';

const JobTableRow = ({ job, user, handleJobClose }) => {
  const navigate = useNavigate(); // Hook para redirigir al usuario

  const getStatusBadge = (status) => {
    const statusClasses = {
      Open: "bg-green-100 text-green-800",
      Closed: "bg-red-100 text-red-800",
      Pending: "bg-yellow-100 text-yellow-800",
    };
    return <span className={`px-2 py-1 rounded-full text-sm font-semibold ${statusClasses[status]}`}>{status}</span>;
  };

  const copiarLink = () => {
    navigator.clipboard.writeText(`${VITE_BASE_URL}/job-description/${job.id}`).then(() => {
      toast.success("Link copied!");
    }).catch(err => {
      toast.error("Error when copying the link:", err);
    });
  };


  return (
    <tr key={job.id} className="hover:bg-[#0A0F29]">
      <td className="px-6 py-4 whitespace-nowrap text-white">{job.jobName}</td>
      <td className="px-6 py-4 whitespace-nowrap">{getStatusBadge(job.positionStatus)}</td>
      <td className="px-6 py-4 whitespace-nowrap text-center text-white">{job.applicationCount}</td> 
      
      <td className="py-4 whitespace-nowrap text-center">
        {job.positionStatus === 'Pending' ? null : 
          user.userType === 'Admin-FLAS-1721' ? (
            <Link to={`/admin-companies-job-detail/${job.id}`}>
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 me-1 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                View applications
              </button>
            </Link>
          ) : (
            <Link to={`/vacancy-detail-fc/${job.id}`}>
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-3 me-1 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex items-center justify-center"
              >
                <AiOutlineEye className="text-xl" />
              </button>
            </Link>
          )
        }
      </td>

      <td className="py-4 whitespace-nowrap text-center">
        {job.positionStatus === 'Open' && (
          <Link to={`/recomendation-list/${job.id}`}>
              <button
            type="button"
            className="text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm p-3 me-1 mb-2 dark:bg-yellow-500 dark:hover:bg-yellow-600 focus:outline-none dark:focus:ring-yellow-800 flex items-center justify-center"
          >
            <FaUserPlus className="text-xl" />
          </button>
          </Link>
          
        )}
      </td>

      <td className="py-4 whitespace-nowrap text-center">
        {job.positionStatus === 'Open' && (
          <Link to={`/vacancy-view/${job.id}`}>
            <button
              type="button"
              className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm p-3 me-1 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800 flex items-center justify-center"
            >
              <FaRegEdit className="text-xl" />
            </button>
          </Link>
        )}
      </td>

      <td className="py-4 whitespace-nowrap text-center">
        {job.positionStatus === 'Open' && (
          <button
            onClick={copiarLink}
            type="button"
            className="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg text-sm p-3 me-1 mb-2 dark:bg-teal-600 dark:hover:bg-teal-700 focus:outline-none dark:focus:ring-teal-800 flex items-center justify-center"
          >
            <BiLinkAlt className="text-xl" />
          </button>
        )}
      </td>

      

      <td className="py-4 whitespace-nowrap text-center">
        {job.positionStatus === 'Open' && (
          <button
            onClick={() => handleJobClose(job.id)}
            type="button"
            className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm p-3 mb-2 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-800 flex items-center justify-center"
          >
            <AiFillLock className="text-xl" />
          </button>
        )}
      </td>

      <ToastContainer />
    </tr>
  );
};

export default JobTableRow;
