import React from 'react';
import { Link } from 'react-router-dom';

function JobCard({ job }) {
    return (
        <Link
            className="p-6 bg-white rounded-lg shadow-lg flex flex-col sm:flex-row items-start sm:items-center hover:shadow-2xl hover:scale-[1.02] transition-all duration-300 ease-in-out"
            to={`/job-description/${job.id}`}
        >
            {/* Encabezado */}
            <div className="w-full">
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-xl font-semibold text-gray-800">{job.jobName}</h2>
                    <span className="font-semibold text-xs px-3 py-1 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200">
                        {job.tbl_company.companyName}
                    </span>
                </div>
                <hr className="my-3 border-gray-300" />

                {/* Contenido */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {/* Tecnologías */}
                    <div>
                        <span className="text-gray-700 font-medium">Technologies</span>
                        <div className="flex flex-wrap mt-1">
                            {job?.mustHaveTechnologies.slice(0, 5).map((tech, index) => (
                                <span
                                    key={index}
                                    className="text-white text-sm mr-2 mb-1 px-2 py-1 bg-orange-500 rounded-lg hover:bg-orange-600 transition-colors duration-200"
                                >
                                    {tech}
                                </span>
                            ))}
                            {job?.mustHaveTechnologies.length > 5 && (
                                <span className="text-white text-sm mr-2 mb-1 px-2 py-1 bg-orange-500 rounded-lg">
                                    ...
                                </span>
                            )}
                        </div>
                    </div>

                    {/* Salario */}
                    <div>
                        <span className="text-gray-700 font-medium">Salary (USD)</span>
                        <span className="text-gray-600 block mt-1">${job.salary}</span>
                    </div>

                    {/* Seniority */}
                    <div>
                        <span className="text-gray-700 font-medium">Seniority</span>
                        <span className="text-gray-600 block mt-1">{job.seniority}</span>
                    </div>

                    {/* Working Scheme */}
                    <div>
                        <span className="text-gray-700 font-medium">Working Scheme</span>
                        <span className="text-gray-600 block mt-1">{job.workingScheme}</span>
                    </div>
                </div>

                {/* Nueva Sección Separada para Nivel de Inglés y Ubicación */}
                <hr className="my-4 border-gray-300" />
                <div className="grid grid-cols-2 gap-4">
                    {/* Nivel de inglés */}
                    <div>
                        <span className="text-gray-700 font-medium">English Level</span>
                        <div>
                            <span className="inline-block mt-1 px-3 py-1 bg-blue-500 text-white text-sm rounded-full hover:bg-blue-600 transition-colors duration-200">
                                {job.englishLevel}
                            </span>
                        </div>
                    </div>

                    {/* Ubicación */}
                    <div>
                        <span className="text-gray-700 font-medium">Location</span>
                        <div>
                            <span className="inline-block mt-1 px-3 py-1 border border-blue-400 text-blue-500 text-sm rounded-full hover:bg-blue-100 transition-colors duration-200">
                                {job.locationAvailability}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default JobCard;

