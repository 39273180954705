import React from 'react';
import { Link } from 'react-router-dom';

const ApplicationCard = ({ application }) => {
  const statusClass = application.application.status === 'Applied'
    ? 'bg-blue-500 text-white'
    : application.application.status === 'Answered'
    ? 'bg-green-500 text-white'
    : application.application.status === 'Seen'
    ? 'bg-yellow-500 text-white'
    : application.application.status === 'Revision'
    ? 'bg-purple-500 text-white'
    : application.application.status === 'Rejected'
    ? 'bg-red-500 text-white'
    : application.application.status === 'Contacted'
    ? 'bg-indigo-500 text-white'
    : application.application.status === 'Accepted'
    ? 'bg-teal-500 text-white'
    : 'bg-gray-500 text-white';

  return (
    <div className="group bg-[#0A0F29] text-white p-6 rounded-xl shadow-md border border-gray-700 relative transition-all duration-300 hover:scale-[1.05] hover:shadow-2xl hover:border-transparent hover:bg-gradient-to-tr from-[#141e46] via-[#1b275f] to-[#0A0F29]">
      {/* Título y Empresa */}
      <h3 className="text-xl font-semibold mb-2 group-hover:text-indigo-400 transition-colors duration-300">
        {application.job.jobName}
      </h3>
      <span className="text-sm text-gray-400 mb-4 block group-hover:text-gray-300 transition-colors duration-300">
        {application.company}
      </span>

      {/* Test Status */}
      {application.application.statusOfTest ? (
        <div className="flex flex-col items-center mb-4">
          <p className="text-green-400 font-bold mb-2 group-hover:scale-105 transition-transform duration-300">
            Test completed
          </p>
          <div className="w-full bg-gray-700 rounded-full h-3 overflow-hidden">
            <div
              className={`${
                application.application.score <= 50
                  ? 'bg-red-500'
                  : application.application.score <= 79
                  ? 'bg-yellow-400'
                  : 'bg-green-500'
              } h-3 rounded-full transition-all duration-300`}
              style={{
                width: `${
                  application.application.isCoderByte
                    ? application.application.score
                    : application.application.score * 10
                }%`,
              }}
            ></div>
          </div>
          <span className="text-xs mt-1 group-hover:text-gray-200 transition-colors duration-300">
            {application.application.isCoderByte
              ? `${application.application.score}%`
              : `${application.application.score}/10`}
          </span>
        </div>
      ) : (
        <div className="flex justify-between items-center mb-4">
          <Link
            to={
              application.application.isCoderByte
                ? application.job.assessmentUrl
                : `/challenge/${application.job.id}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-gradient-to-r from-green-500 to-green-700 text-white px-4 py-2 rounded-lg shadow hover:shadow-xl hover:from-green-400 hover:to-green-600 transition-all duration-300">
              Take Test
            </button>
          </Link>
          <p className="text-red-400 font-bold group-hover:text-red-300 transition-colors duration-300">
            Please complete the test
          </p>
        </div>
      )}

      {/* Etiqueta de estado estilizada */}
      <span
        className={`absolute top-4 right-4 px-4 py-2 rounded-full text-xs font-bold ${statusClass} shadow-md transition-transform duration-300 group-hover:scale-105 group-hover:shadow-lg`}
      >
        {application.application.status.charAt(0).toUpperCase() + application.application.status.slice(1)}
      </span>
    </div>
  );
};

export default ApplicationCard;
