import axios from 'axios'
import ACTION_TYPES from '../../actionTypes'


const getNewsForAdminSuccess = (jobs) => {
    return {
        type: ACTION_TYPES.GET_NEWS_FOR_ADMIN_SUCCESS,
        payload: jobs
    }
}

const getNewsForAdminFailure = (error) => {
    return {
        type: ACTION_TYPES.GET_NEWS_FOR_ADMIN_FAILURE,
        payload: error
    }
}

export const getNewsForAdmin = (title='') => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/news-for-admin?title=${title}`)
            const news = response.data
            dispatch(getNewsForAdminSuccess(news))
        } catch (error) {
            dispatch(getNewsForAdminFailure(error.message))
        }
    }
}

