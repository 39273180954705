import ACTION_TYPES from "./actionTypes"

const INITIAL_STATE = {
    user: {
        id: '',
        isAuthenticated: false,
        name: '',
        email: '',
        userType: null,
        isCompleteInfo: null,
        error: null
    },
    candidates: [],
    jobs: [],
    jobsFC: [],
    jobsFA: [],
    jobsFIC:[],
    openJobs: [],
    pendingJobs: [],
    companies: [],
    assessments: [],
    recruitersNC: [],
    recruiters: [],
    tests:{},
    candidate: null,
    company: null,
    job: null,
    error: null,
    report: null,
    newsFA:[]
}

const rootReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.AUTHENTICATED_SUCCESS:
            return {
                ...state, 
                user: {
                    ...state.user,
                    id: action.payload.id,
                    name: `${action.payload.firstName} ${action.payload.lastName}`,
                    email: action.payload.email,
                    isAuthenticated: true,
                    userType: action.payload.userType,
                    isCompleteInfo: action.payload.isCompleteInfo,
                    error: null
                }
            }
        case ACTION_TYPES.AUTHENTICATED_FAILURE:
            return {
                ...state, 
                user: { ...state.user, error: action.payload }
            }
        case ACTION_TYPES.LOGOUT_SUCCESS:
            return {
                ...state, 
                user: {
                    ...state.user,
                    isAuthenticated: false,
                    name: '',
                    email: '',
                    userType: null,
                    error: null
                }
            }
        case ACTION_TYPES.LOGOUT_FAILURE:
            return {
                ...state, 
                user: { ...state.user, error: action.payload }
            }
        case ACTION_TYPES.GET_CANDIDATES_SUCCESS:
            return {
                ...state, candidates: action.payload
            }
        case ACTION_TYPES.GET_CANDIDATES_FAILURE:
            return {
                ...state, candidates: [], error: action.payload
            }
        case ACTION_TYPES.GET_CANDIDATE_BY_ID_SUCCESS:
            return {
                ...state, candidate: action.payload
            }
        case ACTION_TYPES.GET_CANDIDATE_BY_ID_FAILURE:
            return {
                ...state, candidate: null, error: action.payload
            }
        case ACTION_TYPES.GET_CANDIDATE_BY_FILTERS_SUCCESS:
            return {
                ...state, candidates: action.payload
            }
        case ACTION_TYPES.GET_CANDIDATE_BY_FILTERS_FAILURE:
            return {
                ...state, candidates: [], error: action.payload
            }
        case ACTION_TYPES.GET_JOBS_SUCCESS:
            return {
                ...state, jobs: action.payload
            }
        case ACTION_TYPES.GET_JOBS_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_JOB_BY_ID_SUCCESS:
            return {
                ...state, job: action.payload
            }
        case ACTION_TYPES.GET_JOB_BY_ID_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_JOBS_BY_FILTERS_SUCCESS:
            return {
                ...state, jobs: action.payload
            }
        case ACTION_TYPES.GET_JOBS_BY_FILTERS_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.UPDATE_USER_INFORMATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    id: action.payload.id,
                    name: `${action.payload.firstName} ${action.payload.lastName}`,
                    email: action.payload.email,
                    isAuthenticated: true,
                    userType: action.payload.userType,
                    isCompleteInfo: action.payload.isCompleteInfo,
                    error: null
                }
            }
        case ACTION_TYPES.UPDATE_USER_INFORMATION_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_COMPANY_BY_ID_SUCCESS:
            return {
                ...state, company: action.payload
            }
        case ACTION_TYPES.GET_COMPANY_BY_ID_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_COMPANIES_SUCCESS:
            return {
                ...state, companies: action.payload
            }
        case ACTION_TYPES.GET_COMPANIES_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_COMPANIES_FOR_RECRUITER_SUCCESS:
            return {
                ...state, companies: action.payload
            }
        case ACTION_TYPES.GET_COMPANIES_FOR_RECRUITER_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_OPEN_JOBS_FOR_RECRUITER_SUCCESS:
            return {
                ...state, openJobs: action.payload
            }
        case ACTION_TYPES.GET_OPEN_JOBS_FOR_RECRUITER_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_PENDING_JOBS_FOR_RECRUITER_SUCCESS:
            return {
                ...state, pendingJobs: action.payload
            }
        case ACTION_TYPES.GET_PENDING_JOBS_FOR_RECRUITER_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_ASSESSMENTS_SUCCESS:
            return {
                ...state, assessments: action.payload
            }
        case ACTION_TYPES.GET_ASSESSMENTS_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_RECRUITERS_NC_FROM_COMPANY_SUCCESS:
            return {
                ...state, recruitersNC: action.payload
            }
        case ACTION_TYPES.GET_RECRUITERS_NC_FROM_COMPANY_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_RECRUITERS_FROM_COMPANY_SUCCESS:
            return {
                ...state, recruiters: action.payload
            }
        case ACTION_TYPES.GET_RECRUITERS_FROM_COMPANY_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_COMPANIES_TO_ADD_SUCCESS:
            return {
                ...state, companies: action.payload
            }
        case ACTION_TYPES.GET_COMPANIES_TO_ADD_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_ALL_JOBS_FOR_COMPANY_SUCCESS:
            return {
                ...state, jobsFC: action.payload
            }
        case ACTION_TYPES.GET_ALL_JOBS_FOR_COMPANY_FAILURE:
            return {
                ...state, jobsFC: [], error: action.payload
            }
        case ACTION_TYPES.GET_REPORT_SUCCESS:
            return {
                ...state, report: action.payload
            }
        case ACTION_TYPES.GET_REPORT_FAILURE:
            return {
                ...state, report: null, error: action.payload
            }
        case ACTION_TYPES.SET_ERROR_NULL:
            return {
                ...state, error: null
            }
        case ACTION_TYPES.SET_USER_ERROR_NULL:
            return {
                ...state, user: { ...state.user, error: null }
            }
        case ACTION_TYPES.UPDATE_CANDIDATE_BY_ID_SUCCESS:
            return {
                ...state, candidate: action.payload
            }
        case ACTION_TYPES.UPDATE_CANDIDATE_BY_ID_FAILURE:
            return {
                ...state, error: action.payload
            }
        case ACTION_TYPES.GET_JOBS_FOR_ADMIN_SUCCESS:
            return {
                ...state, jobsFA: action.payload
            }
        case ACTION_TYPES.GET_JOBS_FOR_ADMIN_FAILURE:
            return {
                ...state, jobsFA: [], error: action.payload
            }
        case ACTION_TYPES.GET_JOBS_FOR_INVITE_CANDIDATE_SUCCESS:
            return {
                ...state, jobsFIC: action.payload
            }
        case ACTION_TYPES.GET_JOBS_FOR_INVITE_CANDIDATE_FAILURE:
            return {
                ...state, jobsFIC: [], error: action.payload
            }
        case ACTION_TYPES.GET_TESTS_SUCCESS:
            return {
                ...state, tests: action.payload
            }
        case ACTION_TYPES.GET_TESTS_FAILURE:
            return {
                ...state, tests: [], error: action.payload
            }
        case ACTION_TYPES.GET_NEWS_FOR_ADMIN_SUCCESS:
            return{
                ...state, newsFA: action.payload
            }
        case ACTION_TYPES.GET_NEWS_FOR_ADMIN_FAILURE:
            return{
                ...state, newsFA: [], error: action.payload
            }
        
        default:
            return { ...state }
    }
}

export default rootReducer;




// case GET_PEOPLE:
        //     return{...state, people:action.payload}
            
        // case ADD_PERSON:
        //     return { 
        //         ...state, people:[...state.people, action.payload]
        //     }