import React from 'react';
import Pagination from '../../components/Pagination/Pagination';

const JobPositionsPagination = ({ jobsPerPage, totalJobs, paginate, page }) => {
    return (
        <div className="flex items-center justify-center py-4">
            <Pagination
                jobsPerPage={jobsPerPage}
                totalJobs={totalJobs}
                paginate={paginate}
                page={page}
            />
        </div>
    );
};

export default JobPositionsPagination;