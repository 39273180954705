// admin
import ReactQuill from "react-quill";

const CreateNewsForm = ({formRef, form, handleChange, handleSubmitForm, errors, handleFileChange, formButton, handleQuillChange, body }) =>{

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'bullet' }],
    ]

    return(
        <form ref={formRef} className="mt-8 space-y-6 m-10 mb-10" onSubmit={handleSubmitForm}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Title */}
            <div className="col-span-1">
                <label className="block text-gray-700">
                    Title <span className="text-red-500">*</span>
                </label>
                {errors.title && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.title}</span>
                </div>
                
                )}
                <input
                type="text"
                name="title"
                value={form.title}
                onChange={handleChange}
                placeholder="Your post title..."
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
            </div>
            {/* images */}
            <div className="col-span-1">
                <label className="block text-gray-700">Images <span className="text-red-500">*</span></label> 
                <input type="file" onChange={handleFileChange} className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" multiple value={form.images.value}/>
            </div>
            
            {/* body */}
            <div className="col-span-1 md:col-span-2">
                <label className="block text-gray-700">Body</label>
                {errors.body && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.body}</span>
                </div>
                
                )}

                {/* Parte a modificar */}
                <ReactQuill                  
                value={body}
                onChange={(data)=>handleQuillChange(data)}
                className="bg-white text-black rounded-lg h-40 mb-16"
                modules={{
                    toolbar: toolbarOptions,
                }}
                placeholder="Write something..."
                />
                {/* <textarea
                name="body"
                onChange={handleChange}
                value={form.body}
                placeholder="Write something..."
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-32"
                ></textarea> */}
            </div>
            
            {/* CoreValue */}
            
            {/* reference link */}
            <div className="col-span-1 md:col-span-2">
                <label className="block text-gray-700">
                Reference Link
                </label>
                {errors.referenceLink && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.referenceLink}</span>
                </div>
                
                )}
                <input
                type="text"
                name="referenceLink"
                value={form.referenceLink}
                onChange={handleChange}
                placeholder="https://www.referecelink.com/"
                className="block w-full p-2 pl-4 pr-12 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
            </div>
            </div>
            {/* Submit Button */}
            <div className="text-center">
            <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm w-full hover:bg-blue-700"
            >
                {formButton}
            </button>
            </div>
        </form>
    )
}

export default CreateNewsForm;