import React, { useState, useEffect } from "react";
import Slide1 from "../../assets/LANDING/GRAPHICS/Grafico_explore.png";
import Slide2 from "../../assets/LANDING/GRAPHICS/Grafico_ia2.png";
import Slide3 from "../../assets/LANDING/GRAPHICS/Technical.png";

import { Link } from "react-router-dom";
const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  const slides = [
    
    Slide2,
    Slide3,
    Slide1
   
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 10000); 
    return () => clearInterval(interval); 
  }, [slides.length]);

  
  const handleNextSlide = () => {
    setIsSliding(true); 
    setTimeout(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
      setIsSliding(false); 
    }, 500); 
  };

  const handlePreviousSlide = () => {
    setIsSliding(true); 
    setTimeout(() => {
      setCurrentSlide((prev) =>
        prev === 0 ? slides.length - 1 : prev - 1
      );
      setIsSliding(false);
    }, 500);
  };
  return (

    <div className="relative bg-[#0A0F29] min-h-screen flex items-center justify-center">
      {/* Blur Gradient */}

      <div className="absolute mt-60 inset-0 bg-gradient-to-t from-[#000] via-[#2563EB] to-[#FF7616] blur-2xl opacity-45"></div>

      {/* Hero Content */}
      <div className="relative z-10 w-12/12 md:w-10/12 py-20 text-center">  

        {/* Hero Text */}
        <h1 className="text-6xl font-bold text-white">
        LIA: Your <span className="text-blue-500">   AI-Powered <p></p></span>{" Tech"}
          <span className="text-orange-500"> Recruiter. </span>
        </h1>
        <p className=" md:mt-4 lg:mt-4 py-2 text-3xl font-thin text-gray-300">
        LIA learns your business inside and out to find, evaluate, and hire top-tier tech talent across LATAM and beyond—24/7. Faster, more precise, and more efficient than ever.
        </p>


        <div className="flex md:hidden lg:hidden justify-center items-center space-x-6 py-44 text-center">
              
                <Link target="_blank" to="/signup" className="md:hidden lg:hidden bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded-lg">
                 Connect Now
                </Link>
             
                <Link target="_blank" to="https://calendly.com/sergio_razo_ryscode/30min" className="md:hidden lg:hidden bg-orange-500 hover:bg-gray-200 text-slate-50  text-center font-bold py-4 px-10 rounded-lg">
                Schedule a Demo
                </Link>
          
            </div>

   
        {/* Slider */}
        <div className="hidden md:flex lg:flex flex-col mb-4 py-32">
      
          <div className="relative overflow-hidden  ">  
            <div className=" rounded-md">
              <div className="h-full flex items-center justify-center text-xl font-semibold">
                <div
              className={` flex transition-transform duration-500 ease-in-out ${
                isSliding ? "-translate-x-full" : "translate-x-0"
              }`}
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              {slides.map((slide, index) => (
                <img
                  key={index}
                  src={slide}
                  alt={`Slide ${index + 1}`}
                  className="min-w-full h-full object-cover"
                />
              ))}
            
              </div>
              </div>
            </div>
          </div>
         <div className=" z-[-2] hidden md:absolute lg:flex w-auto rounded-xl mb-48 ml-[-50px] mr-[-50px] mt-80 inset-0 bg-gradient-to-t from-[#0A0F29] via-[#2563EB] to-[#FF7616] blur-[1px] opacity-70">

         </div>

          {/* Slider Buttons */}
          <div className="flex justify-center items-center mt-4 gap-2">

          <button
              onClick={handlePreviousSlide}
              className="bg-blue-500 text-white py-0.5 px-2 rounded-2xl hover:bg-blue-600 transition-all"
            >
              {"<"}
            </button>
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => handleSlideChange(index)}
                className={`h-3 w-3 rounded-full ${
                  currentSlide === index ? "bg-blue-500" : "bg-blue-900"
                }`}
              ></button>
            ))}
                 <button
              onClick={handleNextSlide}
              className="bg-blue-500 text-white py-0.5 px-2 rounded-2xl hover:bg-blue-600 transition-all"
            >
               {">"}
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default HeroSection;
