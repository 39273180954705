import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Importar Link
import { format } from 'date-fns';

const formatDate = (isoDate) => format(new Date(isoDate), 'dd/MM/yyyy');
const formatTime = (isoDate) => format(new Date(isoDate), 'HH:mm');
const stripHTML = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');


const NewsList = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        axios
            .get('/news') 
            .then((response) => {
                setNews(response.data);
            })
            .catch((error) => {
                console.error('Error fetching news:', error);
            });
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Link copied to clipboard!');
        });
    };

    return (
        <div className="bg-[#0A0F29] min-h-screen p-8">
            <h1 className="text-white text-4xl font-extrabold mb-8 text-center">
                Latest Blog Posts
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {news.map((item) => (
                    <Link
                        key={item.id}
                        to={`/news/${item.id}`}
                        className="group bg-[#1C2333] rounded-xl shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl p-6 flex flex-col space-y-4"
                    >
                        {/* Imagen de portada */}
                        {item.images && item.images.length > 0 && (
                            <img
                                src={item.images[0]} // Solo la primera imagen
                                alt={item.title}
                                className="w-full h-40 object-cover rounded-md mb-4"
                            />
                        )}
                        {/* Título */}
                        <h2 className="text-white text-xl font-semibold group-hover:text-blue-400 transition">
                            {item.title}
                        </h2>
                        {/* Contenido breve */}
                        <p className="text-gray-300 line-clamp-3">
                            {item.body ? stripHTML(item.body) : 'No content available'}
                        </p>
                        {/* Fecha y hora */}
                        <p className="text-sm text-gray-500 mt-auto">
                            Posted on: {item.createdAt ? formatDate(item.createdAt) : 'Unknown date'} at{' '}
                            {item.createdAt ? formatTime(item.createdAt) : 'Unknown time'}
                        </p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default NewsList;
