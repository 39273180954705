import React from 'react';

const SignInHeader = ({ logoSrc, title }) => {
    return (
        <>
            <div className="flex justify-center mb-4  bg-[#0A0F29] text-white">
                <img src={logoSrc} alt="Logo" className="w-16 h-16 rounded-full shadow-md" />
            </div>
            <h2 className="text-3xl font-semibold text-center  bg-[#0A0F29] text-white">{title}</h2>
        </>
    );
};

export default SignInHeader;
