import React, { useState } from 'react';

const JobFilter = ({ onFilterChange }) => {
const [selectedFilter, setSelectedFilter] = useState('All');

const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    onFilterChange(filter); // Envía el filtro seleccionado al componente padre
};

return (
    <div className="flex space-x-4 my-4">
    <button
        onClick={() => handleFilterChange('All')}
        className={`px-4 py-2 rounded-lg font-semibold ${selectedFilter === 'All' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
    >
        All
    </button>
    <button
        onClick={() => handleFilterChange('Pending')}
        className={`px-4 py-2 rounded-lg font-semibold ${selectedFilter === 'Pending' ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-800'}`}
    >
        Pending
    </button>
    <button
        onClick={() => handleFilterChange('Open')}
        className={`px-4 py-2 rounded-lg font-semibold ${selectedFilter === 'Open' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'}`}
    >
        Open
    </button>
    <button
        onClick={() => handleFilterChange('Close')}
        className={`px-4 py-2 rounded-lg font-semibold ${selectedFilter === 'Close' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-800'}`}
    >
        Close
    </button>
    </div>
);
};

export default JobFilter;
