import React from 'react';
import BenefitsCard from './BenefitsCard';

const benefits = [
  {
    title: "Save Time",
    description: " Streamline your recruitment by over 80% so you can stay focused on innovation and growth.",
  },
  {
   
    title: "Spend Smarter",
    description: "Cut costs by up to 85% compared to traditional headhunting and staffing services—no compromise on quality.",
  },
  {
    title: "Real Results with Advanced AI",
    description: "Our AI evaluates both technical and cultural skills, ensuring a perfect fit.",
  },
  {
    title: "Performance That Counts",
    description: " Boost talent retention by 70%, ensuring a perfect match between your company and those who power it forward.",
  },
];

const BenefitsSection = () => {
  return (
    <section className="bg-[#0A0F29] text-white py-10">
      <h2 className="text-2xl text-center font-semibold mb-6">Key Benefits</h2>
      <div className="flex flex-wrap justify-center gap-6">
        {benefits.map((benefit) => (
          <BenefitsCard 
            key={benefit.title} 
            title={benefit.title} 
            description={benefit.description} 
          />
        ))}
      </div>
    </section>
  );
};

export default BenefitsSection;
