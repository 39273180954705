import Carousel from "../../components/Carousel/Carousel";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import CTASection from "../../components/Landing/CTASection";
import TestimonialsCarousel from "../../components/Landing/TestimonialsCarousel";
import BenefitsSection from "../../components/Landing/BenefitKey/BenefitsSection";
import HeroSection from "../../components/Landing/HeroSection";

import wlogo1 from "../../assets/LANDING/LogosBlancos/riot.png";
import wlogo2 from "../../assets/LANDING/LogosBlancos/clubspeed.png";
import wlogo3 from "../../assets/LANDING/LogosBlancos/michelada.png";
import wlogo4 from "../../assets/LANDING/LogosBlancos/moonfactory.png";
import wlogo5 from "../../assets/LANDING/LogosBlancos/inceptio.png";
import wlogo6 from "../../assets/LANDING/LogosBlancos/mirigos.png";
import wlogo7 from "../../assets/LANDING/LogosBlancos/vatilana.png";



const HomePage = () => {

  const logos = [
    { src: wlogo1, alt: 'Riot Games' },
    { src: wlogo2, alt: 'Club Speed' },
    { src: wlogo3, alt: 'Michelada.io' },
    { src: wlogo4, alt: 'The Moon Factory' },
    { src: wlogo5, alt: 'Inceptio' },
    { src: wlogo6, alt: 'Mirigos' },
    { src: wlogo7, alt: 'Vatilana' },
    
  ];

  return (
    <div className="bg-[#0A0F29] text-white ">
      {/* Hero Section */}
      <HeroSection />
      <Carousel logos={logos} />
    {/* How It Works */}
    <section className="bg-gradient-to-t from-[#0A0F29] to-black">
          <HowItWorks />
      </section>
      {/* Key Benefits */}
    <BenefitsSection/> 
    {/* CTA Section */}
    <CTASection/> 
    {/* Testimonial Section */}
    <TestimonialsCarousel/> 
    </div>
  );
};

export default HomePage;
