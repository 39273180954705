import { useEffect, useState } from "react";
import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { FaArrowRightLong, FaWandMagicSparkles } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import IA from '../../assets/Vector_AI.svg'
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading'

const ReportChallenge = () => {
  const user = useSelector(state => state.user)
  const { candidateId, jobId } = useParams();
  const [report, setReport] = useState(null);
  const [requestSendStatus, setRequestSendStatus] = useState(false)

  // feedBackWithAI Incluye null o el objeto


  useEffect(() => {
    if (candidateId || jobId) {
      axios.get(`/completed-test/${candidateId}/${jobId}`).then((response) => {
        const fetchedReport = response.data;
        setReport(fetchedReport);
      });
    }
  }, [candidateId, jobId]);

  const handleFeedbackClick = async () => {
    try {
      setRequestSendStatus(true)

      const response = await axios.put(`/recommendation-candidate/${report.id}`, {userId: user.id});
      
      if (response.status === 200) {
        toast.success("Recommendation generated successfully",{
          onOpen: () => {
            setRequestSendStatus(false)
          }
        });
        const updatedReport = await axios.get(`/completed-test/${candidateId}/${jobId}`);        
        setReport({...report, feedBackWithAI: updatedReport.data.feedBackWithAI});
      }else{
        throw new Error("An error occurred in the request. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message,{
          onOpen: () => {
            setRequestSendStatus(false)
          }
        });
      } else {
        toast.error("An error occurred in the request. Please try again.",{
          onOpen: () => {
            setRequestSendStatus(false)
          }
        });
      }
    }
  };

  if (!report) {
    return <>Loading</>;
  }

 
  const pieData = [
    { name: "Correct", value: report.technicalScore },
    { name: "Incorrect", value: 10 - report.technicalScore },
  ];

  return (
    <div className="p-8 bg-[#0A0F29] min-h-screen flex flex-col items-center">
      <div className="bg-[#0A0F29] p-8 rounded-xl shadow-lg w-full max-w-full">
        {/* User Information */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-2xl py-1 font-semibold text-white">{report.name}</h2>
            <p className="text-md pb-2 text-white">{report.email}</p>
            
        <div>
          <Link target="_blank" to={`/candidate-detail/${candidateId}`}>
            <button className="bg-[#FF7616] p-2 rounded-lg text-white flex justify-center items-center hover:bg-orange-400 transition-all">
              Visit candidate profile
              <FaArrowRightLong style={{ marginLeft: "10px" }} />
            </button>
          </Link>
        </div>
          </div>

          <div className="flex justify-center mb-10 mt-6">
         
          <div className="w-48 h-48">
          <p className="text-xl font-bold text-center text-white">
              Score: <span className="text-primary">{report.technicalScore} / 10 </span>
            </p>
            <ResponsiveContainer>
              <PieChart>
              <defs>
                 <linearGradient id="grad1" x1="1" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#F97316" />
                  <stop offset="50%" stopColor="#9C6190" />
                   <stop offset="100%" stopColor="#1751FF" />
                 </linearGradient>
                 <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="1">
                   <stop offset="0%" stopColor="#FFB784" />
                   <stop offset="50%" stopColor="#E3C1C6" />
                   <stop offset="100%" stopColor="#A1C2FF" />
                </linearGradient>
              </defs>
                <Pie
                  data={pieData}
                  innerRadius={50}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={`url(#grad${index + 1})`}
                    />
                  ))}
                </Pie>
                <Tooltip />  
              </PieChart>
              
            </ResponsiveContainer>
          </div>
        </div>
        </div>


        {/* Button to trigger feedback */}
        {report.feedBackWithAI === null && (
          <div className="flex justify-center mt-6">
            <button className='flex p-3 rounded-lg bg-purple-500 text-white hover:bg-purple-600' 
              onClick={handleFeedbackClick} 
            >Generate Recommendation (BETA)<FaWandMagicSparkles className="ml-3" size={23}/></button>
          </div>
        )}

        {/* Recommendation Section */}
        { report.feedBackWithAI != null && (
          <div className=" mt-6 p-16 bg-[#1F2937] border border-[#1F2937] rounded-lg shadow">
            <div className="flex ">
          <h3 className="text-5xl font-semibold mr-2 text-white">IA EVALUATION  </h3>  <img src={IA} className='ml-4 w-12 h-auto' />   </div>   
          <div className="flex-cols lg:flex">
            
            <div className="mt-10 lg:pr-20">
              <h4 className="text-lg font-medium text-green-700">Good Features:</h4>
              <p className="text-white mt-1">{report.feedBackWithAI.goodFeatures}</p>
            </div>
            <div className="mt-10">
              <h4 className="text-lg font-medium text-red-700">Bad Features:</h4>
              <p className="text-white mt-1">{report.feedBackWithAI.badFeatures}</p>
            </div>
            <div className="mt-10 lg:pl-20">
              <h4 className="text-lg font-medium text-blue-700">Recommendation IA (BETA):</h4>
              <p className="text-white mt-1">{report.feedBackWithAI.OpinionOfAI}</p>
            </div>
          </div>
          </div>
        )}

        {/* Technical Test */}
        <div className=" mt-6 p-16 bg-[#1F2937] border border-[#1F2937] rounded-lg shadow">
        <div>
          <h3 className="text-xl font-semibold text-white mb-4">
            Technical Test
          </h3>
          <div className="grid grid-cols-1  md:grid-cols-2 space-y-4 space-x-4">
            <div className="fixed ">
            </div>

            {report.technicalTest.map((answer, index) => (
              <div
                key={index}
                className={`flex items-center h-14 justify-between p-4  rounded-lg border ${
                  answer.correct
                    ? "bg-green-50 border-green-200"
                    : "bg-red-50 border-red-200"
                }`}
              >
                <div className="flex items-center">
                  {answer.correct ? (
                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 text-red-500" />
                  )}
                  <p className="ml-4 text-gray-700">{answer.question}</p>
                </div>
                <p className="text-gray-500 italic">Selected: {answer.answer}</p>
              </div>
            ))}
            
          </div>
          
        </div>
        </div>

        {/* Open-Ended Questions */}
        <div className=" mt-6 p-16 bg-[#1F2937] border border-[#1F2937] rounded-lg shadow">
          <h3 className="text-xl font-semibold text-white mb-4">
            Psychometric Test
          </h3>
          <div className=" space-y-4 space-x-4">
            <div className="fixed"></div>
            {report.psychometricTest.questions.map((item, index) => (
              <div
                key={index}
                className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-200"
              >
                <p className="text-gray-600 font-semibold mb-2">Question:</p>
                <p className="text-gray-800 font-medium mb-3">{item.question}</p>
                <p className="text-gray-600 font-semibold mb-2">Answer:</p>
                <p className="text-gray-800  ">{item.answer}</p>
              </div>
              
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Loading loading={requestSendStatus} background="rgba(0, 0, 0, 0.6)" loaderColor="#3498db" />
    </div>
  );
};

export default ReportChallenge;
