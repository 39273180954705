import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Para obtener ID de URL y redirigir
import { format } from 'date-fns';
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";
import DOMPurify from 'dompurify';
import './style.css'


const formatDate = (isoDate) => format(new Date(isoDate), 'dd/MM/yyyy');
const formatTime = (isoDate) => format(new Date(isoDate), 'HH:mm');


const Details = () => {
    const { id } = useParams(); // Obtiene el ID desde la URL
    const navigate = useNavigate(); 
    const [post, setPost] = useState(null);
    const sanitizedNewsBody = DOMPurify.sanitize(post?.body)

    useEffect(() => {
        axios
            .get(`/news/${id}`)
            .then((response) => {
                setPost(response.data);
            })
            .catch((error) => {
                console.error('Error fetching post:', error);
                alert('Error loading post. Redirecting to home.');
                navigate('/'); // Redirige al home si hay un error
            });
    }, [id, navigate]);

    if (!post) {
        return (
            <div className="text-white min-h-screen flex justify-center items-center">
                <p>Loading...</p>
            </div>
        );
    }

    const { title, body, createdAt, images } = post;
    
    let trueImages = []
    if (images.length > 0) {
        trueImages = images.map((image)=>{
            return {
                original: image,
                thumbnail: image,
            }
        })
        
    }

    return (
<div className="bg-[#0A0F29] min-h-screen p-6 sm:p-8 text-white mt-20 mx-4 sm:mx-10">
    <button
        onClick={() => navigate(-1)}
        className="text-blue-400 hover:underline mb-4"
    >
        ← Back
    </button>
    <div className="max-w-4xl mx-auto">
        {/* Título del post */}
        <h1 className="text-2xl sm:text-4xl font-extrabold mb-4 text-center sm:text-left">{title}</h1>
        {/* Fecha y hora */}
        <p className="text-xs sm:text-sm text-gray-400 mb-6 text-center sm:text-left">
            Posted on: {formatDate(createdAt)} at {formatTime(createdAt)}
        </p>
        <div className="prose prose-invert max-w-none text-gray-300 text-sm sm:text-base mb-10 "
        dangerouslySetInnerHTML={{__html: sanitizedNewsBody}}
        >
            {/* <p>{sanitizedNewsBody}</p> */}
            {/* <div
        className="text-gray-300 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: sanitizedJobDescription }}
      /> */}
        </div>
        {trueImages.length > 0 ? <ImageGallery showPlayButton={false} showBullets={true} items={trueImages}/> : null}
        
    </div>
</div>

    );
};
// showPlayButton
export default Details;
