import React, { useEffect, useState } from 'react';

import ErickMartinez from "../../assets/personas/ErickMartinez.jpg";
import JoseHernandez from "../../assets/personas/JoseHernandez.jpg";
import JuanCarlosCatro from "../../assets/personas/JuanCarlosCatro.jpg";
import LuisSoto from "../../assets/personas/LuisSoto.jpg";
import RicardoMedrano from "../../assets/personas/RicardoMedrano.jpg";

// Datos de los testimonios
const testimonials = [
  {
    id: 1,
    name: 'María G.',
    role: 'CEO of TechStart',
    img: 'https://i.pinimg.com/474x/7e/2e/12/7e2e129ddb43977eb8d2c9c1c9e4a6f1.jpg',
    text: 'Thanks to RysConnect.io, we hired a senior developer in less than a week. An innovative and efficient solution!',
  },
  {
    id: 2,
    name: 'Ricardo Medrano',
    role: 'CEO of Inceptio',
    img: RicardoMedrano,
    text: 'Our experience with RysConnect has been very good. They provided candidates on time and matched the requested profiles.',
  },
  {
    id: 3,
    name: 'Erick Martinez',
    role: 'IT Sourcer at Encora',
    img: ErickMartinez,
    text: 'The experience with RysConnect was exceptional. The quality, attention, and communication they provide are outstanding.',
  },
  {
    id: 4,
    name: 'Luis Soto',
    role: 'Director at Fibotech',
    img: LuisSoto,
    text: 'RysConnect impressed us with the speed and agility with which they presented candidates for interviews.',
  },
  {
    id: 5,
    name: 'José Hernandez',
    role: 'HR Manager LATAM at Teamwork Commerce',
    img: JoseHernandez,
    text: 'RysConnect has been key to finding the best talent for our company.',
  },
];

const TestimonialsCarousel = () => {
const [current, setCurrent] = useState(0);

  // Cambio automático de testimonios
useEffect(() => {
    const interval = setInterval(() => {
    setCurrent((prev) => (prev + 1) % testimonials.length);
    }, 5000); // Cambio cada 5 segundos
    return () => clearInterval(interval);
}, []);

return (
    <section className="bg-[#0A0F29] text-white py-10 pb-40 px-6">
        <h2 className="text-3xl font-bold italic text-center mb-6">Customer Testimonials</h2>
        <div className="relative overflow-hidden max-w-4xl mx-auto">
            <div
            className="flex transition-transform duration-700"
            style={{ transform: `translateX(-${current * 100}%)` }}
            >
            {testimonials.map((testimonial) => (
                <div
                key={testimonial.id}
                className="min-w-full p-6 md:p-8 flex items-center space-x-6 bg-white text-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                <img
                    src={testimonial.img}
                    alt={testimonial.name}
                    className="rounded-full w-24 h-24 object-cover"
                />
                <div>
                    <p className="italic mb-4">"{testimonial.text}"</p>
                    <h5 className="font-bold">— {testimonial.name}, {testimonial.role}</h5>
                </div>
                </div>
            ))}
            </div>
            {/* Controles de navegación */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {testimonials.map((_, index) => (
                <button
                key={index}
                onClick={() => setCurrent(index)}
                className={`h-3 w-3 rounded-full ${
                    current === index ? 'bg-blue-500' : 'bg-gray-400'
                }`}
                ></button>
            ))}
            </div>
        </div>
        </section>
    );
};

export default TestimonialsCarousel;
