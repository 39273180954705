import React from 'react';

const JobDetailsGrid = ({ job }) => {
  return (
<div className="border-t border-gray-600 mt-6 pt-6">
  <h2 className="text-2xl font-bold text-white mb-4">Job Details</h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 text-lg">
    {/* Ubicación */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">📍 Location</p>
      <p className="text-gray-300">{job.locationAvailability}</p>
    </div>

    {/* Esquema de trabajo */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">🛠️ Work Scheme</p>
      <p className="text-gray-300">{job.workingScheme}</p>
    </div>

    {/* Seniority */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">🎓 Seniority</p>
      <p className="text-gray-300">{job.seniority}</p>
    </div>

    {/* Años de experiencia */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">📅 Years of Experience</p>
      <p className="text-gray-300">{job.yearsOfExperience}</p>
    </div>

    {/* Tipo de trabajo */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">💼 Job Type</p>
      <p className="text-gray-300">{job.jobType}</p>
    </div>

    {/* Nivel de inglés */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">🗣️ English Level</p>
      <p className="text-gray-300">{job.englishLevel}</p>
    </div>

    {/* Término del contrato */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md">
      <p className="font-semibold text-blue-400">📑 Contract Term</p>
      <p className="text-gray-300">{job.contractTerm}</p>
    </div>

    {/* Must-Haves */}
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg shadow-md col-span-1 sm:col-span-2 md:col-span-3">
      <p className="font-semibold text-blue-400">✅ Must-Haves</p>
      <p className="text-gray-300">{job.mustHaveTechnologies.join(', ')}</p>
    </div>
  </div>
</div>

  );
};

export default JobDetailsGrid;