const validation = (data)=>{
    let isError = false
    let incorrect = {}

    let title = data.title.trim()
    let body = data.body.replace(/<\/?[^>]+(>|$)/g, "").trim()
    let referenceLink = data.referenceLink.trim()

    
    const regexURL =  RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    // const regexLetters = RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ,.]+$/)
    
    if (!title){
        incorrect.title = "Title cannot be empty."
        isError = true
    }
    
    if (!body){
        incorrect.body = "Body cannot be empty."
        isError = true
    }

    if (referenceLink) {
        if(!regexURL.test(referenceLink)){
            incorrect.referenceLink = "URL format. example: https://www.referecelink.com/"
            isError = true
        }
    }

    return isError ? incorrect : null
}

export default validation