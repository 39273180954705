const ACTION_TYPES = {
    AUTHENTICATED_SUCCESS : 'AUTHENTICATED_SUCCESS',
    AUTHENTICATED_FAILURE : 'AUTHENTICATED_FAILURE',
    LOGOUT_SUCCESS : 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE : 'LOGOUT_FAILURE',
    GET_CANDIDATES_SUCCESS : 'GET_CANDIDATES_SUCCESS',
    GET_CANDIDATES_FAILURE : 'GET_CANDIDATES_FAILURE',
    GET_CANDIDATE_BY_ID_SUCCESS : 'GET_CANDIDATE_BY_ID_SUCCESS',
    GET_CANDIDATE_BY_ID_FAILURE : 'GET_CANDIDATE_BY_ID_FAILURE',
    GET_CANDIDATE_BY_FILTERS_SUCCESS : 'GET_CANDIDATE_BY_FILTERS_SUCCESS',
    GET_CANDIDATE_BY_FILTERS_FAILURE: 'GET_CANDIDATE_BY_FILTERS_FAILURE',
    GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
    GET_JOBS_FAILURE: 'GET_JOBS_FAILURE',
    GET_JOB_BY_ID_SUCCESS: 'GET_JOB_BY_ID_SUCCESS',
    GET_JOB_BY_ID_FAILURE: 'GET_JOB_BY_ID_FAILURE',
    GET_JOBS_BY_FILTERS_SUCCESS: 'GET_JOBS_BY_FILTERS_SUCCESS',
    GET_JOBS_BY_FILTERS_FAILURE: 'GET_JOBS_BY_FILTERS_FAILURE',
    PUR_CANDIDATE_SUCCESS: 'PUR_CANDIDATE_SUCCESS',
    PUR_CANDIDATE_FAILURE: 'PUR_CANDIDATE_FAILURE',
    POST_COMPLETE_COMPANY_INFORMATION_SUCCESS: 'POST_COMPLETE_COMPANY_INFORMATION_SUCCESS',
    POST_COMPLETE_COMPANY_INFORMATION_FAILURE: 'POST_COMPLETE_COMPANY_INFORMATION_FAILURE',
    UPDATE_USER_INFORMATION_SUCCESS: 'UPDATE_USER_INFORMATION_SUCCESS',
    UPDATE_USER_INFORMATION_FAILURE: 'UPDATE_USER_INFORMATION_FAILURE',
    GET_COMPANY_BY_ID_SUCCESS: 'GET_COMPANY_BY_ID_SUCCESS',
    GET_COMPANY_BY_ID_FAILURE: 'GET_COMPANY_BY_ID_FAILURE',
    GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
    GET_COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',
    GET_COMPANIES_FOR_RECRUITER_SUCCESS: 'GET_COMPANIES_FOR_RECRUITER_SUCCESS',
    GET_COMPANIES_FOR_RECRUITER_FAILURE: 'GET_COMPANIES_FOR_RECRUITER_FAILURE',
    GET_OPEN_JOBS_FOR_RECRUITER_SUCCESS: 'GET_OPEN_JOBS_FOR_RECRUITER_SUCCESS',
    GET_OPEN_JOBS_FOR_RECRUITER_FAILURE: 'GET_OPEN_JOBS_FOR_RECRUITER_FAILURE',
    GET_PENDING_JOBS_FOR_RECRUITER_SUCCESS: 'GET_PENDING_JOBS_FOR_RECRUITER_SUCCESS',
    GET_PENDING_JOBS_FOR_RECRUITER_FAILURE: 'GET_PENDING_JOBS_FOR_RECRUITER_FAILURE',
    GET_ASSESSMENTS_SUCCESS: 'GET_ASSESSMENTS_SUCCESS',
    GET_ASSESSMENTS_FAILURE: 'GET_ASSESSMENTS_FAILURE',
    GET_RECRUITERS_NC_FROM_COMPANY_SUCCESS: 'GET_RECRUITERS_NC_FROM_COMPANY_SUCCESS',
    GET_RECRUITERS_NC_FROM_COMPANY_FAILURE: 'GET_RECRUITERS_NC_FROM_COMPANY_FAILURE',
    GET_RECRUITERS_FROM_COMPANY_SUCCESS: 'GET_RECRUITERS_FROM_COMPANY_SUCCESS',
    GET_RECRUITERS_FROM_COMPANY_FAILURE: 'GET_RECRUITERS_FROM_COMPANY_FAILURE',
    GET_COMPANIES_TO_ADD_SUCCESS: 'GET_COMPANIES_TO_ADD_SUCCESS',
    GET_COMPANIES_TO_ADD_FAILURE: 'GET_COMPANIES_TO_ADD_FAILURE',
    GET_ALL_JOBS_FOR_COMPANY_SUCCESS: 'GET_ALL_JOBS_FOR_COMPANY_SUCCESS',
    GET_ALL_JOBS_FOR_COMPANY_FAILURE: 'GET_ALL_JOBS_FOR_COMPANY_FAILURE',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
    SET_ERROR_NULL : 'SET_ERROR_NULL',
    SET_USER_ERROR_NULL: 'SET_USER_ERROR_NULL',
    UPDATE_CANDIDATE_BY_ID_SUCCESS: 'UPDATE_CANDIDATE_BY_ID_SUCCESS',
    UPDATE_CANDIDATE_BY_ID_FAILURE: 'UPDATE_CANDIDATE_BY_ID_FAILURE',
    GET_JOBS_FOR_ADMIN_SUCCESS: 'GET_JOBS_FOR_ADMIN_SUCCESS',
    GET_JOBS_FOR_ADMIN_FAILURE: 'GET_JOBS_FOR_ADMIN_FAILURE',
    GET_JOBS_FOR_INVITE_CANDIDATE_SUCCESS: 'GET_JOBS_FOR_INVITE_CANDIDATE_SUCCESS',
    GET_JOBS_FOR_INVITE_CANDIDATE_FAILURE: 'GET_JOBS_FOR_INVITE_CANDIDATE_FAILURE',
    GET_TESTS_SUCCESS:'GET_TESTS_SUCCESS',
    GET_TESTS_FAILURE: 'GET_TESTS_FAILURE',
    GET_NEWS_FOR_ADMIN_SUCCESS: 'GET_NEWS_FOR_ADMIN_SUCCESS',
    GET_NEWS_FOR_ADMIN_FAILURE: 'GET_NEWS_FOR_ADMIN_FAILURE',
}

export default ACTION_TYPES