import React from 'react';
import { Link } from 'react-router-dom';
import DefaultP from '../../assets/default_p.jpg';

const Card = ({ candidates }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full bg-[#0A0F29] p-4">
      {candidates?.map((candidate) => (
        <Link
          key={candidate.id}
          to={`/candidate-detail/${candidate.id}`}
          className="transform hover:scale-105 transition-transform"
        >
          <div className="bg-[#1A2238] p-6 rounded-lg shadow-xl text-white flex flex-col items-center">
            <img
              src={DefaultP}
              alt={`Profile of ${candidate.firstName}`}
              className="rounded-full h-24 w-24 mb-4 border-4 border-[#FFA500]"
            />
            <div className="text-right w-full">
              <span
                className={`text-sm font-bold py-1 px-3 rounded ${
                  candidate.technicalLevel === "Junior"
                    ? "bg-blue-600 text-white"
                    : candidate.technicalLevel === "Senior"
                    ? "bg-purple-600 text-white"
                    : "bg-red-600 text-white"
                }`}
              >
                {candidate.technicalLevel}
              </span>
            </div>
            <h3 className="text-xl font-semibold mt-2 text-center">
              {candidate.firstName || candidate.tbl_user.firstName}{" "}
              {candidate.lastName || candidate.tbl_user.secondName}
            </h3>
            <p className="text-gray-300 text-center">{candidate.role}</p>
            <p className="text-gray-400 mt-2 text-center">{candidate.country}</p>
            <p className="text-gray-400 text-center">${candidate.salaryExpectations}</p>
            <div className="flex flex-wrap justify-center mt-4 gap-2">
              {candidate.mainTech?.slice(0, 5).map((tech) => (
                <span
                  key={tech}
                  className="text-sm font-bold py-1 px-3 rounded bg-[#FFA500] text-white"
                >
                  {tech}
                </span>
              ))}
              {candidate.mainTech?.length > 5 && (
                <span className="text-sm font-bold py-1 px-3 rounded bg-[#FFA500] text-white">
                  ...
                </span>
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Card;
