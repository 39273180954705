import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getJobById } from '../../redux/actions/Candidate/getJobById';
import axios from 'axios'
import JobHeader from '../../components/JobDetails/JobHeader';
import JobDetailsGrid from '../../components/JobDetails/JobDetailsGrid';
import JobDescriptionSection from '../../components/JobDetails/JobDescriptionSection';
import ApplyButton from '../../components/JobDetails/ApplyButton';
import { ToastContainer, toast } from 'react-toastify';


const JobDescription = () => {
  const { id } = useParams();
  const job = useSelector(state => state.job)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  

useEffect(() => {
  dispatch(getJobById(id))
}, [id, dispatch]);  


if (!job) {
  return <p>Loading job details...</p>;
}


const handleSubmit = async (e) => {

try {
  const obj = {
    jobId: id,
    userId: user.id
}
    const response = await axios.post(`/add-applications`, obj)
    if (response.status === 200) {
      
      if (response.data.message) {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 3000
        });
      }else{
        toast.success('You have applied to the vacancy correctly, now please answer the vacancy tests in "My positions"', {
          position: "top-center",
          autoClose: 3000,
          onClose: () => {
              navigate('/my-application');
          }
        });
      }
    }
} catch (error) {
  if (error.response) {
    toast.error(error.response.data.error, {
      position: "top-center",
      autoClose: 3000
    });
  } else {
    toast.error("An error occurred in the request. Please try again.", {
      position: "top-center",
      autoClose: 3000
    });
  }
}

}



return (
  <div className="w-full h-full bg-gradient-to-b from-[#0A0F29] to-[#1E3A8A] pt-8">
    <div className="max-w-7xl mx-auto mb-5">
      <div className="border rounded-lg p-6">
        <JobHeader job={job} />
        <JobDetailsGrid job={job} />
        <JobDescriptionSection job={job} />
      </div>
    </div>
    <ApplyButton handleSubmit={handleSubmit} user={user}/>
    <ToastContainer />
  </div>
);
};

export default JobDescription