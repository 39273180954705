import { useState } from "react";
import { Link } from "react-router-dom";

function Dropdown({accountName, userType, onLogout}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const getProfileLink = () => {
    if (userType === "Company") return "/company-profile";
    if (userType === "Candidate") return "/profile-candidates";
    return "/profile"; // Enlace por defecto
  };

  return (
    <div className="relative inline-block">
      
      <button
        onClick={toggleDropdown}
        className="flex py-2 px-6 font-medium tracking-wide text-white capitalize transition-colors duration-500 transform border bg-tr rounded-lg hover:bg-white hover:text-[#0A0F29] hover:border-[#0A0F29] focus:outline-none focus:ring focus:ring-blue-200 focus:bg-white focus:text-[#0A0F29] focus:ring-opacity-80"
      >
        <span className="mx-1">User</span>
        <svg
          className="w-5 h-5"
          viewBox=""
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>

      {/* Dropdown */}
      {isOpen && (
        <div
          onClick={() => closeDropdown()}
          className="flex lg:flex-col lg:absolute lg:right-0 lg:z-20 lg:w-56 py-2 mt-2 overflow-hidden lg:origin-top-right bg-white rounded-md shadow-xl "
        >

<Link
  to={getProfileLink()}
  className="flex items-center p-3 rounded-lg text-sm text-white bg-[#F97316] hover:bg-orange-400"
>
  <>
  <svg
      width="20"
      height="22"
      className="mr-2 "
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.249 4.75C12.249 5.74456 11.8539 6.69839 11.1507 7.40165C10.4474 8.10491 9.49356 8.5 8.499 8.5C7.50444 8.5 6.55061 8.10491 5.84735 7.40165C5.14409 6.69839 4.749 5.74456 4.749 4.75C4.749 3.75544 5.14409 2.80161 5.84735 2.09835C6.55061 1.39509 7.50444 1 8.499 1C9.49356 1 10.4474 1.39509 11.1507 2.09835C11.8539 2.80161 12.249 3.75544 12.249 4.75ZM1 18.868C1.03213 16.9004 1.83634 15.0242 3.23918 13.644C4.64202 12.2639 6.53109 11.4905 8.499 11.4905C10.4669 11.4905 12.356 12.2639 13.7588 13.644C15.1617 15.0242 15.9659 16.9004 15.998 18.868C13.6454 19.9468 11.0871 20.5035 8.499 20.5C5.823 20.5 3.283 19.916 1 18.868Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {accountName}
   
  </>
</Link>


          <hr className="border-gray-200 py-1" />

            <a
            className="flex items-center p-3 rounded-r-lg text-sm lg:rounded-lg text-white bg-[#3E7AFF] capitalize transition-colors duration-300 transform hover:bg-blue-400 "
            onClick={onLogout}
        >
            <svg width="22" height="22" className="mx-1" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8333 9.375H14.3333M11.8958 4.90625C11.8958 5.3864 11.8013 5.86184 11.6175 6.30544C11.4338 6.74903 11.1645 7.15209 10.8249 7.49161C10.4854 7.83112 10.0824 8.10044 9.63876 8.28418C9.19517 8.46793 8.71972 8.5625 8.23958 8.5625C7.75943 8.5625 7.28399 8.46793 6.84039 8.28418C6.3968 8.10044 5.99373 7.83112 5.65422 7.49161C5.3147 7.15209 5.04539 6.74903 4.86164 6.30544C4.6779 5.86184 4.58333 5.3864 4.58333 4.90625C4.58333 3.93655 4.96854 3.00657 5.65422 2.32089C6.3399 1.63521 7.26988 1.25 8.23958 1.25C9.20928 1.25 10.1393 1.63521 10.8249 2.32089C11.5106 3.00657 11.8958 3.93655 11.8958 4.90625ZM1.33333 18.8379V18.7188C1.33333 16.8871 2.06095 15.1305 3.35612 13.8353C4.6513 12.5401 6.40793 11.8125 8.23958 11.8125C10.0712 11.8125 11.8279 12.5401 13.123 13.8353C14.4182 15.1305 15.1458 16.8871 15.1458 18.7188V18.8368C13.0609 20.0925 10.6723 20.7541 8.23849 20.75C5.71324 20.75 3.35049 20.0512 1.33333 18.8368V18.8379Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span className="mx-1">Log out</span>
          </a>
          

        </div>
      )}
    </div>
  );
}

export default Dropdown;