import React from 'react';
import SearchBar from '../SearchBar/SearchBar';

const HireTalentHeader = ({ searchCandidates }) => {
  return (
    <header className="text-center mt-8 md:mt-16">
      <div className='py-8'>
      <h1 className="text-3xl font-bold text-white">Meet Your Next Top Performers</h1>
      <p className="text-gray-400">
        Explore a Curated Selection of Top Professionals Ready to Drive Your Business Forward
      </p>
      </div>
      <SearchBar searchCandidates={searchCandidates} />
    </header>
  );
};

export default HireTalentHeader;