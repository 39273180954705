import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignInForm = ({ form, onHandleChange, onHandleSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const isFormValid = form.email && form.password; // Validación básica

    return (
        <div className="relative w-full max-w-md mx-auto">
            {/* Gradient Background */}
            <div className="absolute inset-0 bg-gradient-to-t from-[#000] via-[#2563EB] to-[#FF7616] blur-2xl opacity-45 rounded-lg"></div>

            {/* Form Content */}
            <form
                className="relative z-10 mt-8 space-y-6 p-8 bg-[#0A0F29] text-white rounded-lg shadow-lg"
                onSubmit={onHandleSubmit}
            >
                {/* Email Input */}
                <div className="rounded-md shadow-sm space-y-4">
                    <div>
                        <label htmlFor="email" className="sr-only">
                            Email
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="relative block w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 bg-[#0A0F29] text-white sm:text-sm"
                            placeholder="Your Email"
                            value={form.email}
                            onChange={onHandleChange}
                        />
                    </div>

                    {/* Password Input */}
                    <div>
                        <label htmlFor="password" className="sr-only">
                            Password
                        </label>
                        <div className="relative">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                required
                                className="relative block w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 bg-[#0A0F29] text-white"
                                placeholder="Password"
                                value={form.password}
                                onChange={onHandleChange}
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 px-3 bg-[#0A0F29] text-white z-20 focus:outline-none"
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                                aria-hidden="true"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div>
                    <button
                        type="submit"
                        className={`relative flex justify-center w-full px-6 py-3 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 ${
                            isFormValid
                                ? 'bg-orange-500 hover:bg-orange-600'
                                : 'bg-gray-400 cursor-not-allowed'
                        }`}
                        disabled={!isFormValid}
                    >
                        Sign In
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SignInForm;
