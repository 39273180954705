import React, { useState } from "react";

const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: "What is LIA and how does it work?",
      answer:
        "LIA is an AI-powered tech recruiter that learns about your company to find, evaluate, and hire top-tier talent. Simply share your technical and cultural requirements, and LIA takes care of the entire process—from sourcing candidates in her own database and external channels, to running assessments and recommending final candidates. Thanks to her autonomous AI capabilities, she handles repetitive recruitment tasks, saving you both time and money.",
    },
    {
      question: "Which roles or specialties does LIA focus on?",
      answer:
        "LIA is designed to cover a broad range of tech and innovation roles, including:\nSoftware Developers (Frontend, Backend, Full Stack)\nData Scientists and Data Analysts\nDevOps and Cloud Engineers\nUX/UI Specialists\nQA, Cybersecurity, and more\nHer AI engine tailors its search strategy to each specific position, ensuring you find highly qualified candidates for your needs.",
    },
    {
      question: "How is LIA different from a traditional recruiting agency?",
      answer:
        "Unlike conventional agencies, LIA automates much of the sourcing and evaluation process, which:\n- Saves you over 80% of the time you’d normally spend recruiting\n- Reduces costs by up to 85% compared to headhunting and staffing firms\n- Delivers a more accurate 'match' between candidate and company, improving talent retention by 70%\nPlus, LIA integrates seamlessly into your existing workflows, and her AI-based approach continually evolves to deliver even better results over time.",
    },
    {
      question: "What kinds of assessments does LIA conduct for candidates?",
      answer:
        "Leveraging an AI engine, LIA designs customized assessments that cover:\nTechnical Skills: From coding challenges to algorithmic problem-solving, tailored to each role\nCultural Fit: Questionnaires and interactive exercises that measure values, communication styles, and alignment with your company culture\nThese tests adapt to each position, ensuring candidates meet both technical requirements and core values.",
    },
    {
      question: "What are LIA’s reports like, and what do they include?",
      answer:
        "LIA’s reports provide:\nDetailed results from both technical and cultural assessments\nCandidate comparisons featuring key metrics and scores\nActionable recommendations to help you quickly decide who advances in the process\nPersonalized feedback for each candidate, enhancing their experience as well as your hiring team’s\nReports are generated automatically within minutes, giving you the critical insights you need to make confident hiring decisions.",
    },
    {
      question: "How long does a typical recruitment process take with LIA?",
      answer:
        "Timelines vary depending on the complexity of the role and candidate availability. However, companies using LIA often cut their hiring cycles by 50% or more. In practice, what once took weeks—or even months—can now be condensed into just a few days, thanks to LIA’s AI-driven filtering and assessment.",
    },
    {
      question: "How much does it cost to use LIA?",
      answer:
        "We offer flexible plans to suit businesses of all sizes, from startups to large enterprises. You can even create a free account to explore our core features and see firsthand how LIA can transform your tech recruitment process.",
    },
    {
      question: "Is LIA available for my region (LATAM, the US, Europe, etc.)?",
      answer:
        "Absolutely! LIA is built to support companies worldwide, with a special focus on LATAM and strong global connections. Our platform easily adapts to various markets, ensuring access to top-tier talent wherever you are.",
    },
    {
      question: "How does LIA handle data security for my company and candidates?",
      answer:
        "We apply high standards of encryption and security protocols to safeguard all sensitive information. We comply with key data protection regulations (like GDPR) and continuously monitor our systems to keep your recruitment processes private and secure.",
    },
    {
      question: "Can LIA integrate with my internal systems (ATS, CRM, or other platforms)?",
      answer:
        "Yes! LIA offers APIs and connectors that make it easy to integrate with applicant tracking systems (ATS), HR CRMs, and other workflow tools you might use. This ensures data consistency and a seamless hiring experience across all platforms.",
    },
    {
      question: "What kind of support can I expect throughout the hiring process?",
      answer:
        "Our support team is here to guide you every step of the way:\nAccount setup and creating your first job openings\nInterpreting reports, metrics, and candidate results\nTechnical troubleshooting for integrations or specialized configurations\nYou can reach us via live chat, email, or phone—whichever is most convenient for you.",
    },
  ];

  return (
    <div className="bg-[#0A0F29] text-white min-h-screen p-8">
      <h1 className="text-4xl font-bold text-center py-10">Frequently Asked Questions (FAQ)</h1>
      <div className="max-w-4xl mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-700 rounded-lg overflow-hidden hover:bg-gray-800 transition duration-300"
          >
            <button
              onClick={() => toggleQuestion(index)}
              className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
            >
              <span className="text-lg font-medium">{faq.question}</span>
              <svg
                className={`w-6 h-6 transition-transform duration-300 ${
                  openQuestion === index ? "rotate-180" : "rotate-0"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {openQuestion === index && (
              <div className="bg-gray-900 p-4 text-gray-300">
                <p className="whitespace-pre-line">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="text-center mt-10">
        <p className="text-lg">Have More Questions?</p>
        <p className="text-gray-400 mt-2">
          If there’s anything else you’d like to know, don’t hesitate to get in touch with our team. We’re here to help.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
