import React from 'react';
import DOMPurify from 'dompurify'
import './style.css'
import { FaPen, FaClipboardList, FaTools, FaInfoCircle, FaBuilding, FaMicrophone } from "react-icons/fa";


const JobDescriptionSection = ({ job }) => {

  const sanitizedJobDescription = DOMPurify.sanitize(job?.jobDescription);
  const sanitizedResponsibilities = DOMPurify.sanitize(job?.responsibilities)
  const sanitizedQualificationsAndSkills = DOMPurify.sanitize(job?.qualificationsAndSkills)

  return (
<div className="border-t border-gray-600 mt-6 pt-6">
  <h2 className="text-2xl font-bold text-white mb-4">Job Overview</h2>
  <div className="space-y-6 text-lg">
    {/* Descripción */}
    <div className="bg-gray-800 p-5 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-blue-400 flex items-center gap-2 mb-2">
        <FaPen className="text-white" /> Description
      </h3>
      <hr className="border-gray-700 mb-4" />
      <div
        className="text-gray-300 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: sanitizedJobDescription }}
      />
    </div>

    {/* Responsabilidades */}
    <div className="bg-gray-800 p-5 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-blue-400 flex items-center gap-2 mb-2">
        <FaClipboardList className="text-white" /> Responsibilities
      </h3>
      <hr className="border-gray-700 mb-4" />
      <div
        className="text-gray-300 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: sanitizedResponsibilities }}
      />
    </div>

    {/* Calificaciones y habilidades */}
    <div className="bg-gray-800 p-5 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-blue-400 flex items-center gap-2 mb-2">
        <FaTools className="text-white" /> Qualifications and Skills
      </h3>
      <hr className="border-gray-700 mb-4" />
      <div
        className="text-gray-300 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: sanitizedQualificationsAndSkills }}
      />
    </div>

    {/* Información adicional */}
    <div className="bg-gray-800 p-5 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-blue-400 flex items-center gap-2 mb-2">
        <FaInfoCircle className="text-white" /> Additional Information
      </h3>
      <hr className="border-gray-700 mb-4" />
      <p className="text-gray-300 leading-relaxed">
        {job.additionalInformation}
      </p>
    </div>

    {/* Sobre la empresa */}
    <div className="bg-gray-800 p-5 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-blue-400 flex items-center gap-2 mb-2">
        <FaBuilding className="text-white" /> About the Company
      </h3>
      <hr className="border-gray-700 mb-4" />
      <p className="text-gray-300 leading-relaxed">
        {job.tbl_company.companyDescription}
      </p>
    </div>

    {/* Etapas de la entrevista */}
    <div className="bg-gray-800 p-5 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-blue-400 flex items-center gap-2 mb-2">
        <FaMicrophone className="text-white" /> Interview Stages
      </h3>
      <hr className="border-gray-700 mb-4" />
      <p className="text-gray-300 leading-relaxed">
        {job.interviewStages}
      </p>
    </div>
  </div>
</div>

  );
};

export default JobDescriptionSection;