import React from 'react';
import JobTableRow from './JobTableRow';

const JobTableBody = ({ jobs, user, handleJobClose}) => {
  return (
    <tbody className="bg-[#0A0F29] divide-y divide-[#0B0F29]">
      {jobs.map((job) => (
        <JobTableRow key={job.id} job={job} user={user} handleJobClose={handleJobClose} />
      ))}
    </tbody>
  );
};

export default JobTableBody;
