import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Frame1 from '../../assets/LANDING/HOW/Frame1.png'
import Frame2 from '../../assets/LANDING/HOW/Frame2.png'
import Frame3 from '../../assets/LANDING/HOW/Frame3.png'




const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className="text-white">
      <div
        className={`absolute left-0 top-0 h-full w-2 transition-all duration-1000 ${
          isOpen ? 'scale-y-100' : 'scale-y-0'
        } origin-top`}
      />
      <button
        className="flex justify-between items-center text-left w-full py-4 text-3xl font-bold tracking-tight text-white"
        onClick={() => {
          if (!isOpen) onClick()
        }}
        disabled={isOpen}
      >
        {title}
        <span
          className={`transform transition-transform duration-1000 ${isOpen ? 'rotate-180' : ''}`}
        >
          <svg
            width="19"
            height="13"
            viewBox="0 0 19 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 2L9.5 10.9203L2 2"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
      <div
        className={`overflow-hidden transition-all duration-1000 ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        {isOpen && (
          <div className="pb-4 flex font-thin text-xl flex-col">
            {content}
            <div className="py-2"></div>
            <Link
              target="_blank"
              to="/signup"
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded-lg"
            >
              Connect Now
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default function HowItWorks() {
  const [openItem, setOpenItem] = useState('create')

  const items = [
    {
      id: 'create',
      title: 'Share your vision with LIA',
      content:
        " Tell LIA about your technical and cultural needs. Her AI-driven engine quickly absorbs every detail of your company’s DNA.",
    },
    {
      id: 'ai',
      title: 'Automated, spot-on evaluations',
      content: ' Leveraging advanced AI, LIA develops tailored cultural and technical assessments—delivering personalized feedback to both you and the candidates.',
    },
    {
      id: 'connect',
      title: 'Actionable insights, instantly',
      content:
        ' Generate detailed reports in a snap, select your top prospects, and connect with one click.',
    },
  ]

  const images = {
    create: Frame1,
    ai: Frame2,
    connect: Frame3,
  }

  return (
    <div className="flex justify-evenly items-center max-w-6xl mx-auto p-6 h-auto gap-28">
      <div className="flex-1 h-auto justify-evenly">
        <div className="mr-6 space-y-6">
          <div className="">
            {items.map((item) => (
              <AccordionItem
                key={item.id}
                title={item.title}
                content={item.content}
                isOpen={openItem === item.id}
                onClick={() => setOpenItem(openItem === item.id ? null : item.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`hidden md:flex relative flex-1 aspect-square bg-transparent rounded-lg overflow-hidden transition-all duration-1000 ${
          openItem ? 'h-auto' : 'h-32'
        }`}
      >
        <div className="flex inset-0 items-center justify-center">
          {openItem && (
            <img
              src={images[openItem]}
              alt={openItem}
              className={`w-full h-auto object-cover transition-all duration-1000 ease-in-out transform ${
                openItem ? 'opacity-100 ' : 'opacity-0 '
              }`}
            />
          )}
        </div>
      </div>
    </div>
  )
}