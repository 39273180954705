import PropTypes from 'prop-types';
import { CONSTANTS } from '../../view/About/aboutData';

const FounderCard = ({ name, role, description, image }) => (
  <div className={`bg-[${CONSTANTS.CARD_BG}] p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform`}>
    <img
      src={image}
      alt={name}
      className="w-24 h-24 mx-auto rounded-full mb-4"
    />
    <h4 className="text-xl font-bold text-center mb-2">{name}</h4>
    <p className="text-sm text-center mb-4 font-light italic">{role}</p>
    <p className="text-sm text-center">{description}</p>
  </div>
);

FounderCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default FounderCard;
