import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import logo from '../../assets/Logo/simbolo-SF-04.png';


export default function Footer() {
  const sections = [
    {
      title: "Platform",
      links: [
        { name: "Overview", path: "/signup" },
        { name: "Features", path: "/signup" },
        { name: "Integrations", path: "/signup" },
        { name: "API", path: "/signup" },
        { name: "Documentation", path: "/signup" },
      ],
    },
    {
      title: "Pricing",
      links: [
        { name: "Plans", path: "/signup" },
        { name: "Discounts", path: "/signup" },
        { name: "FAQs", path: "/faqs" },
        { name: "Case Studies", path: "/signup" },
        { name: "Billing Info", path: "/signup" },
      ],
    },
    {
      title: "Positions",
      links: [
        { name: "Open Roles", path: "/signup" },
        { name: "Internships", path: "/signup" },
        { name: "Diversity", path: "/signup" },
        { name: "Culture", path: "/signup" },
        { name: "Benefits", path: "/signup" },
      ],
    },
    {
      title: "More",
      links: [
        { name: "Blog", path: "/signup" },
        { name: "About Us", path: "/about-us" },
        { name: "News", path: "/signup" },
        { name: "Community", path: "/signup" },
        { name: "Contact", path: "/signup" },
      ],
    },
  ];

  return (
    <div className="bg-[#0A0F29] text-white px-6 md:px-20 border border-gray-700">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0">
        {/* Logo and Title */}
        <div className="flex items-center mt-8 space-x-4">
          <img src={logo} alt="Rysconnect Logo" className="w-10 h-16" />
          <span className="text-2xl font-bold">Rysconnect</span>
        </div>
  
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-8">
        {sections.map((section, index) => (
          <div key={index} className="space-y-4">
            <h3 className="text-lg font-semibold">{section.title}</h3>
            <nav className="flex flex-col space-y-2">
              {section.links.map((link, i) => (
                <Link
                  key={i}
                  to={link.path}
                  className="block px-4 py-2 text-gray-300 hover:text-white transition-colors text-sm"
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
        ))}
      </div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center py-8 space-y-8 md:space-y-0">
        {/* Social Media Links */}
        <div className="text-center md:text-left">
          <div className="flex justify-center md:justify-end space-x-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/@rysconnect"
              className="text-white hover:text-red-500 transition"
            >
              <FaYoutube size={28} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/rysconnect-io/"
              className="text-white hover:text-blue-300 transition"
            >
              <FaLinkedin size={28} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/profile.php?id=61555537606972"
              className="text-white hover:text-blue-500 transition"
            >
              <FaFacebookF size={28} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/rysconnect.io/"
              className="text-white hover:text-pink-500 transition"
            >
              <FaInstagram size={28} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}