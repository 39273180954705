import React from 'react';
import DefaultImage from '../../assets/default.jpg';

const JobHeader = ({ job }) => {
  return (
<div className="flex items-center space-x-6 p-6 bg-opacity-80 rounded-lg shadow-md">
  {/* Logo de la empresa */}
  <div className="flex-shrink-0">
    {job.tbl_company.logo ? (
      <img
        src={job.tbl_company.logo}
        alt="Company logo"
        className="h-20 w-20 object-cover rounded-lg shadow-lg"
      />
    ) : (
      <img
        src={DefaultImage}
        alt="Default logo"
        className="h-20 w-20 object-cover rounded-lg shadow-lg"
      />
    )}
  </div>

  {/* Información del trabajo */}
  <div className="flex flex-col justify-center">
    <h1 className="text-3xl font-bold text-blue-600 mb-1">{job.jobName}</h1>
    <p className="text-lg font-bold text-white mb-1">
      {job.companyName || job.tbl_company.companyName}
    </p>
    <p className="text-lg text-gray-300 mb-1">
      {job.salary <= 0 ? '' : `$${job.salary}`}
    </p>
    <a
      href={job.tbl_company.companyLink}
      className="text-lg text-blue-400 hover:text-blue-300 underline transition-all"
      target="_blank"
      rel="noopener noreferrer"
    >
      {job.tbl_company.companyLink}
    </a>
  </div>
</div>

  );
};

export default JobHeader;