import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { logout } from "../../redux/actions/Logout/Logout";
import imagen from "../../assets/Logo/simbolo-SF-04.png";
import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
const Navbar = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onHandleClick = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleMenuClick = () => {
    setIsOpen(false);
  };

  const sections = [
    {
      title: "Platform",
      links: [
        { name: "Overview", path: "/overview" },
        { name: "Features", path: "/features" },
        { name: "Integrations", path: "/integrations" },
        { name: "API", path: "/api" },
        { name: "Documentation", path: "/documentation" },
      ],
    },
    {
      title: "Pricing",
      links: [
        { name: "Plans", path: "/plans" },
        { name: "Discounts", path: "/discounts" },
        { name: "FAQs", path: "/faqs" },
        { name: "Case Studies", path: "/case-studies" },
        { name: "Billing Info", path: "/billing-info" },
      ],
    },
    {
      title: "Positions",
      links: [
        { name: "Open Roles", path: "/open-roles" },
        { name: "Internships", path: "/internships" },
        { name: "Diversity", path: "/diversity" },
        { name: "Culture", path: "/culture" },
        { name: "Benefits", path: "/benefits" },
      ],
    },
    {
      title: "More",
      links: [
        { name: "Blog", path: "/blog" },
        { name: "Events", path: "/events" },
        { name: "News", path: "/news" },
        { name: "Community", path: "/community" },
        { name: "Contact", path: "/contact" },
      ],
    },
  ];


  return (
    <header className="fixed w-full z-[1000] left-0 top-0 bg-[#0A0F29] text-white">
      <div className="mx-auto px-6 py-1 flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <img src={imagen} alt="RysConnect logo" className=" w-10 h-16" />
          <Link to="/" onClick={handleMenuClick}>
            <span className="text-2xl font-semibold tracking-wide">Rysconnect</span>
          </Link>
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="lg:hidden text-white focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
        <nav
          className={`${
            isOpen ? "flex" : "hidden"
          }  flex-col lg:flex lg:flex-row lg:items-center lg:space-x-8 absolute lg:relative top-16 left-0 right-0 lg:top-auto bg-gray-900 lg:bg-transparent z-20 lg:z-auto transition duration-300`}
        > 
              <Link to="/news" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                News
              </Link>
          {user?.isAuthenticated ? (
            <>
              {user.userType === "Admin-FLAS-1721" && (
                <>
                  <Link to="/create-news" className="block lg:inline-block px-4 py-2 hover:text-orange-400 transition">
                    Create news
                  </Link>
                  <Link to="/admin-companies" className="block lg:inline-block px-4 py-2 hover:text-orange-400 transition">
                    Companies
                  </Link>
                  <Link to="/admin-jobs" className="block lg:inline-block px-4 py-2 hover:text-orange-400 transition">
                    Admin
                  </Link>
                  <span onClick={onHandleClick} className="block lg:inline-block text-orange-400 px-4 py-2 cursor-pointer">{user.name}</span>
                </>
              )}

              {user.userType === "Candidate" && user.isCompleteInfo && (
                <>
                  <Link to="/job-positions-list" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    Positions
                  </Link>
                  <Link to="/my-application" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    My positions
                  </Link>
                  <Dropdown
                  accountName={user.name}
                  userType={user.userType}
                  onLogout={onHandleClick}
                />
                </>
              )}

              {user.userType === "Company" && user.isCompleteInfo && (
                <>
                  <Link to="/hire-talent" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    Hire Talent
                  </Link>
                  <Link to="/register-job" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    Register Job
                  </Link>
                  <Link to={`/your-job/${user.id}`} className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                    My Jobs
                  </Link>
                  <Dropdown
                  accountName={user.name}
                  userType={user.userType}
                  onLogout={onHandleClick}
                />
                </>
              )}

            </>
          ) : (
            <>
              <Link to="/job-positions-list" className="block lg:inline-block px-4 py-2 hover:text-gray-400 transition">
                Positions
              </Link>
              <Link to="/signin" className=" px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-500 transform border bg-tr rounded-lg hover:bg-white hover:text-[#0A0F29] hover:border-[#0A0F29] focus:outline-none focus:ring focus:ring-blue-200 focus:bg-white focus:text-[#0A0F29] focus:ring-opacity-80">
                Log In
              </Link>
              <Link to="/signup" className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#F97316] rounded-lg hover:bg-[#FF7A41] focus:outline-none focus:ring focus:ring-[#FF976A] focus:ring-opacity-80">
                Register Now
              </Link>
              <Link target="_blank" to="https://calendly.com/sergio_razo_ryscode/30min" className=" px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                Contact Us
              </Link>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
