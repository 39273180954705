import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApplicationCard from '../../components/JobApplications/ApplicationCard';
import InfoAlert from '../../components/JobApplications/InfoAlert';
import { Link } from 'react-router-dom'



const statuses = [
  'Aplicado',
  'Pendiente de evaluación',
  'En Contacto'
];

const JobApplications = () => {

  const user = useSelector(state => state.user)
  const [applications, setApplications] = useState([])

  useEffect(() => {
    const getApp = async () => {
      if (user) {
        try {
          const response = await axios.get(`/application-for-candidates/?userId=${user.id}`)
          if (response.status === 200) {
            setApplications(response.data)
          }
        } catch (error) {
          if (error.response) {
            alert(error.response.data.error);
          } else {
              alert("An error occurred in the request. Please try again.");
          }
        }
      }
    }
    getApp()
  }, [user]); 

  // /application-for-candidates

  return (
    <div className="p-6 bg-[#0A0F29] min-h-screen">
      <h2 className="text-3xl font-bold mb-6 text-center text-white">
      My applications</h2>
      <InfoAlert />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {applications.map(app => (
          <ApplicationCard key={app.application.id} application={app} />
        ))}
      </div>
    </div>
  );
};

export default JobApplications;
