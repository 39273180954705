import React from 'react';
import { Link } from 'react-router-dom';
import { FaRocket, FaSignInAlt, FaPaperPlane } from 'react-icons/fa';

const ApplyButton = ({ handleSubmit, user }) => {
  return (
<div className="py-12 w-full">
  <div className="max-w-6xl mx-auto px-6 text-center">
    {/* Título Principal */}
    <h2 className="text-3xl md:text-4xl font-extrabold text-white mb-6 flex items-center justify-center gap-2">
      <FaRocket className="text-white text-3xl" />
      Apply Now and Shape Your Future!
    </h2>
    
    {/* Descripción */}
    <p className="text-lg md:text-xl text-gray-300 mb-8">
      {user.isAuthenticated
        ? 'A team member will contact you to start the process.'
        : 'To apply for this vacancy, please sign in.'}
    </p>

    {/* Botón de Acción */}
    <div className="flex justify-center">
      {user.isAuthenticated ? (
        <button
          onClick={handleSubmit}
          className="flex items-center justify-center gap-2 px-10 py-3 border border-white text-white text-lg font-semibold rounded-full shadow-md hover:bg-white hover:text-blue-700 transform hover:scale-105 transition-transform duration-300"
        >
          <FaPaperPlane className="text-white text-xl" />
          Submit Your Application
        </button>
      ) : (
        <Link to="/signin">
          <button className="flex items-center justify-center gap-2 px-10 py-3 border border-white text-white text-lg font-semibold rounded-full shadow-md hover:bg-white hover:text-blue-700 transform hover:scale-105 transition-transform duration-300">
            <FaSignInAlt className="text-white text-xl" />
            Sign In to Apply
          </button>
        </Link>
      )}
    </div>
  </div>
</div>
  );
};

export default ApplyButton;